import { Container, Divider, Box, IconButton } from "@material-ui/core";
import { Add, VisibilityOutlined, RefreshOutlined } from "@material-ui/icons";
import { Grid } from "@mui/material";
import axios from "axios";
import {
  ButtonGroupTop,
  DateTimeDisplay,
  ModalError,
  Page,
  PrimaryButton,
  SearchTextInput,
  SelectInput,
  SkeletonComponent,
  StatusChip
} from "components";
import { topMenuButtonMPIS } from "constants";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { globalStyles } from "styles";
import { getErrors, textCapitalization } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";
import PencilVector from "assets/img/pencilVector.svg";

function MasterRevenueType(props) {
  const classes = globalStyles();
  const history = useHistory();
  const location = useLocation();
  const { userRole } = props;
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const urlParams = new URLSearchParams(location.search);
  const paramsSearch = urlParams.get("search");
  const paramsPage = urlParams.get("page");
  const paramsSize = urlParams.get("per_page");
  const paramsType = urlParams.get("type");

  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: Number(paramsPage) || 1,
    per_page: Number(paramsSize) || 10,
    search: paramsSearch || "",
    type: paramsType || "email",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);

  const handleChangePageParams = (value, key) => {
    urlParams.set(key, value);
    history.push({ search: urlParams.toString() });
  };
  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
    handleChangePageParams(value, key);
    if (key !== "page") {
      handleChangePageParams(1, "page");
    }
  };

  const getDataTable = async () => {
    const { page, per_page, search, type } = queryParams;
    const params = { page, per_page, search };
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/admin/annoucement/table/${type}`,
        { headers, params }
      );

      const { data, meta } = res?.data;
      setDataTable(data || []);
      const pageCount = getTotalPage(meta?.total, queryParams?.per_page);
      setTableTotalPage(pageCount || 1);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );

  useEffect(() => {
    const isSearching = Boolean(queryParams?.search);
    if (isSearching) {
      debounceDataTable();
      return () => {
        debounceDataTable.cancel();
      };
    } else {
      getDataTable();
    }
  }, [queryParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="Announcement Management">
      <Container maxWidth={false}>
        <HeaderTitle title="Configuration" breadcrumbData={breadcrumbData} />
        <ButtonGroupTop items={topMenuButtonMPIS(userRole)} />
        <Divider className={classes.flatDivider} />
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          my="16px"
        >
          <Grid item>
            <SearchTextInput
              placeholder="Search"
              value={queryParams?.search}
              onChange={e =>
                handleChangeQueryParams(e?.target?.value, "search")
              }
            />
          </Grid>
          <Grid item>
            <Grid container columnSpacing={1} alignItems="center">
              <Grid item>
                <SelectInput
                  options={optionsType}
                  optionKey="type"
                  optionLabel="title"
                  value={queryParams?.type || ""}
                  onChange={e =>
                    handleChangeQueryParams(e?.target?.value, "type")
                  }
                  width="100%"
                />
              </Grid>
              <Grid item>
                <PrimaryButton
                  label={`Create ${queryParams?.type === "web" ? "Website" : "Email"
                    } Announcement`}
                  onClick={() =>
                    history.push(
                      `/admin/konfigurasi/announcement-management/add/${queryParams?.type}`
                    )
                  }
                  startIcon={<Add />}
                  size="large"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {loadingPage ? (
          <SkeletonComponent variant="wave" />
        ) : (
          <InnoTableV2
            isLoading={false}
            columns={columnTable({ type: queryParams?.type, classes })}
            items={dataTable}
            page={queryParams?.page}
            rowsPerPage={queryParams?.per_page}
            totalPage={tableTotalPage}
            handleChangePage={(_, page) =>
              handleChangeQueryParams(page, "page")
            }
            handleChangeRowsPerPage={e =>
              handleChangeQueryParams(e?.target?.value, "per_page")
            }
          />
        )}
      </Container>
    </Page>
  );
}
const breadcrumbData = [
  {
    label: "Configuration",
    link: "/admin/konfigurasi/announcement-management",
  },
  {
    label: "Announcement Management",
    active: true,
  },
];
const optionsType = [
  {
    type: "web",
    title: "Website Announcement Content",
  },
  {
    type: "email",
    title: "Email Announcement",
  },
];
const columnTable = ({ type, classes }) => {
  const history = useHistory();
  return [
    {
      name: "all",
      title: "Action",
      renderText: ({ status, id }) => {
        return (
          <Box>
            <IconButton
              className={classes.buttonAction}
              onClick={() =>
                history.push(
                  `/admin/konfigurasi/announcement-management/${type}/${id}`
                )
              }
            >
              <VisibilityOutlined />
            </IconButton>
            {status !== "published" &&
              <IconButton
                className={classes.buttonAction}
                onClick={() =>
                  history.push(
                    `/admin/konfigurasi/announcement-management/edit/${id}`
                  )
                }
              >
                <img src={PencilVector} />
              </IconButton>}
            {status === "draft" && <IconButton
              className={classes.buttonAction}
            >
              <RefreshOutlined />
            </IconButton>}
          </Box>
        );
      }
    },
    {
      name: "type",
      title: "Type",
      renderText: item => textCapitalization(item),
    },
    {
      name: type === "web" ? "start_date" : "delivery_time",
      title: type === "web" ? "Start Date" : "Delivery Time",
      renderText: date => <DateTimeDisplay date={date} />,
    },
    ...(type === "web"
      ? [
        {
          name: "end_date",
          title: "End Date",
          renderText: date => <DateTimeDisplay date={date} />,
        },
      ]
      : []),
    {
      name: "subject",
      title: "Subject",
    },
    {
      name: "status",
      title: "Status",
      renderText: status => {
        const newStatus = textCapitalization(status || "");
        const typeChip = {
          published: "success",
          scheduled: "warning",
          draft: "danger"
        };
        return (
          <StatusChip
            type={typeChip[status]}
            label={newStatus}
          />
        );
      },
    },
  ];
};

export default MasterRevenueType;
