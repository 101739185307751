import { InputAdornment, styled, TextField } from "@mui/material";
import NumberFormat from "react-number-format";

const CurrencyNumberInput = ({
  label,
  required,
  value,
  onChange,
  startAdornment,
  endAdornment,
  width,
  placeholder,
  disabled,
  decimalScale,
}) => {
  const dynamicPlaceholder =
    placeholder || `0${decimalScale > 0 ? "." + "0".repeat(decimalScale) : ""}`;

  return (
    <NumberFormat
      value={value || ""}
      onValueChange={values => {
        onChange(values.floatValue);
      }}
      customInput={CustomTextField}
      fullWidth
      customWidth={width}
      size="small"
      disabled={disabled}
      thousandSeparator={true}
      decimalScale={decimalScale}
      fixedDecimalScale
      allowNegative={false}
      placeholder={dynamicPlaceholder}
      label={label}
      required={required}
      InputProps={{
        startAdornment: startAdornment && (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ),
        endAdornment: endAdornment && (
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        ),
        inputMode: "numeric",
      }}
    />
  );
};

const CustomTextField = styled(TextField)(({ customWidth }) => ({
  width: customWidth,
}));

export default CurrencyNumberInput;
