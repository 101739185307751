import moment from "moment";

export const infiniteDate = date =>
  date === "0001-01-01T00:00:00Z" || date === "0001-01-01";
export const formatDate = date =>
  date && !infiniteDate(date) ? moment(date).format("DD MMM YYYY") : "-";
export const formatTime = date =>
  date && !infiniteDate(date) ? moment(date).format("HH:mm") : "-";
export const formatDateTime = date =>
  date && !infiniteDate(date) ? moment(date).format("DD MMM YYYY, HH:mm") : "-";
export const formatMonth = date =>
  date && !infiniteDate(date) ? moment(date).format("MMM") : "-";
export const formatMonthYear = date =>
  date && !infiniteDate(date) ? moment(date).format("MMM YYYY") : "-";
export const formatPeriodDate = (startDate, endDate) => {
  const invalidDate =
    !startDate || !endDate || infiniteDate(startDate) || infiniteDate(endDate);
  return !invalidDate
    ? `${formatDate(startDate)} - ${formatDate(endDate)}`
    : "-";
};
export const formatPeriodMonthYear = (startDate, endDate) => {
  const invalidDate =
    !startDate || !endDate || infiniteDate(startDate) || infiniteDate(endDate);
  return !invalidDate
    ? `${formatMonthYear(startDate)} - ${formatMonthYear(endDate)}`
    : "-";
};
export const formatPeriodMonth = (startDate, endDate) => {
  const invalidDate =
    !startDate || !endDate || infiniteDate(startDate) || infiniteDate(endDate);
  return !invalidDate
    ? `${formatMonth(startDate)} - ${formatMonth(endDate)}`
    : "-";
};

export const getDatePickerMonth = date => date.month() + 1;
export const setDatePickerMonth = date => moment(date, "MM");
export const getDatePickerYear = date => date.year();
export const setDatePickerYear = date => moment(date, "YYYY");

export default {
  infiniteDate,
  formatDate,
  formatTime,
  formatDateTime,
  formatMonth,
  formatMonthYear,
  formatPeriodMonth,
  formatPeriodMonthYear,
  getDatePickerMonth,
  setDatePickerMonth,
  getDatePickerYear,
  setDatePickerYear,
};
