import { PrimaryIconButton } from "components";
import { Description, VisibilityOutlined } from "@material-ui/icons";
import { Box, Grid, Typography } from "@mui/material";
import TrashVector from "../../../assets/img/trashVector.svg";

const AttachmentFilesCard = ({
  fileName,
  fileSize,
  handleRemove,
  filePath,
  tooltipRemove,
  tooltipPreview,
}) => {
  return (
    <Box border="1px solid #ebebeb" borderRadius="5px" px="16px" py="8px">
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container columnSpacing={2} alignItems="center">
            <Grid item>
              <Description />
            </Grid>
            <Grid item>
              <Typography variant="body1">{fileName}</Typography>
              {fileSize && <Typography variant="body2">{fileSize}</Typography>}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container columnSpacing={1}>
            {filePath && (
              <Grid item>
                <PrimaryIconButton
                  onClick={() => window.open(filePath)}
                  icon={<VisibilityOutlined />}
                  tooltipTitle={tooltipPreview}
                />
              </Grid>
            )}
            {handleRemove && (
              <Grid item>
                <PrimaryIconButton
                  onClick={handleRemove}
                  icon={<img alt="delete" src={TrashVector} />}
                  tooltipTitle={tooltipRemove}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AttachmentFilesCard;
