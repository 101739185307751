import { Container, Divider } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import { Grid } from "@mui/material";
import axios from "axios";
import {
  AutoCompleteComponent,
  BalanceCard,
  CurrencyDisplay,
  DateRangePicker,
  ModalError,
  Page,
  PrimaryButton,
  PrimaryTextButton,
  SearchTextInput,
  SelectInput,
  SkeletonComponent,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import isEqual from "lodash/isEqual";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { globalStyles } from "styles";
import { formatDate, getErrors } from "utils";
import ExcelLogo from "../../../assets/img/excel-logo.png";
import { hardBaseUrl } from "../../../services/urlConstant";

const PaymentPage = () => {
  const classes = globalStyles();
  const history = useHistory();
  const location = useLocation();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const urlParams = new URLSearchParams(location.search);
  const paramsSearch = urlParams.get("search");
  const paramsPage = urlParams.get("page");
  const paramsSize = urlParams.get("size");
  const paramsStatus = urlParams.get("status");
  const paramsComposer = urlParams.get("composer_id");
  const paramsStart = urlParams.get("startDate");
  const paramsEnd = urlParams.get("endDate");

  const [loadingTable, setLoadingTable] = useState(false);
  const [optionComposer, setOptionComposer] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [tableParams, setTableParams] = useState({
    page: Number(paramsPage) || 1,
    size: Number(paramsSize) || 10,
    search: paramsSearch || "",
    status: paramsStatus || "",
  });
  const [queryParams, setQueryParams] = useState({
    composer_id: Number(paramsComposer) || "",
    startDate: paramsStart
      ? getFilterDate(paramsStart)
      : moment().subtract(3, "months"),
    endDate: paramsEnd ? getFilterDate(paramsEnd) : moment(),
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);
  const [summaryDetail, setSummaryDetail] = useState({
    composer_balance: 0,
    composer_advance: 0,
  });
  const prevQueryParams = usePrevious(queryParams);

  const handleChangePageParams = (value, key) => {
    urlParams.set(key, value);
    history.push({ search: urlParams.toString() });
  };
  const handleChangeTableParams = (value, key) => {
    setTableParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
    handleChangePageParams(value, key);
    if (key !== "page") {
      handleChangePageParams(1, "page");
    }
  };
  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      [key]: value,
    }));
    handleChangePageParams(value, key);
  };
  const handleChangeSummaryDetail = (value, key) => {
    setSummaryDetail(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleChangeStartDate = date => {
    handleChangeQueryParams(date, "startDate");
    handleChangePageParams(formatFilterDate(date), "startDate");
  };
  const handleChangeEndDate = date => {
    handleChangeQueryParams(date, "endDate");
    handleChangePageParams(formatFilterDate(date), "endDate");
  };

  const getComposerList = async () => {
    setLoadingTable(true);
    try {
      const res = await axios.get(
        `${hardBaseUrl}/publisher/transaction-composer`,
        { headers }
      );
      const modifiedData = res?.data?.data?.map(item => ({
        ...item,
        id: item?.composer_id,
        label: item?.composer_name,
      }));
      setOptionComposer(modifiedData);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingTable(false);
    }
  };
  const getSummaryBalance = async () => {
    setLoadingTable(true);
    try {
      const res = await axios.get(`${hardBaseUrl}/balance/composer`, {
        headers,
        params: {
          composer_id: queryParams?.composer_id,
          start_date: formatFilterDate(queryParams?.startDate),
          end_date: formatFilterDate(queryParams?.endDate),
        },
      });
      const { data } = res?.data;
      handleChangeSummaryDetail(data?.total, "composer_balance");
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingTable(false);
    }
  };
  const getSummaryAdvance = async () => {
    setLoadingTable(true);
    try {
      const res = await axios.get(`${hardBaseUrl}/payment/composer/advance`, {
        headers,
        params: {
          composer_id: queryParams?.composer_id,
          start_date: formatFilterDate(queryParams?.startDate),
          end_date: formatFilterDate(queryParams?.endDate),
        },
      });
      const { data } = res?.data;
      handleChangeSummaryDetail(data?.total, "composer_advance");
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingTable(false);
    }
  };
  const getDataTable = async () => {
    try {
      setLoadingTable(true);
      const res = await axios.get(
        `${hardBaseUrl}/publisher/transaction/payment`,
        {
          headers,
          params: {
            ...tableParams,
            composer_id: queryParams?.composer_id,
            filter_start_date: formatFilterDate(queryParams?.startDate),
            filter_end_date: formatFilterDate(queryParams?.endDate),
          },
        }
      );
      const { data, meta } = res?.data;
      setDataTable(data);
      const pageCount = getTotalPage(meta?.total, queryParams?.size || 1);
      setTableTotalPage(pageCount);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingTable(false);
    }
  };

  const downloadTemplate = () => {
    const url = `${hardBaseUrl}/publisher/transaction-payment/export`;
    axios
      .get(url, {
        responseType: "blob",
        headers,
      })
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "payment-transaction.csv");
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => {
        new Error(err);
      });
  };

  useEffect(() => {
    getComposerList();
  }, []);

  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams, tableParams]
  );

  useEffect(() => {
    if (prevQueryParams && !isEqual(prevQueryParams, queryParams)) {
      getSummaryBalance();
      getSummaryAdvance();
    }
    debounceDataTable();
    return () => {
      debounceDataTable.cancel();
    };
  }, [queryParams, tableParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="Payment">
      {loadingTable ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title="Payment Transaction"
            breadcrumbData={breadcrumbData}
          />
          <Divider className={classes.divider} />
          <AutoCompleteComponent
            label="Composer/ Author"
            options={optionComposer}
            value={
              optionComposer.find(
                option => option.id === queryParams?.composer_id
              ) || null
            }
            onChange={value => handleChangeQueryParams(value, "composer_id")}
            size="small"
          />
          <Grid container spacing={1} my={2}>
            <Grid item>
              <BalanceCard
                title="Composer/ Author Balance"
                value={summaryDetail?.composer_balance}
              />
            </Grid>
            <Grid item>
              <BalanceCard
                title="Composer/ Author Advance"
                value={summaryDetail.composer_advance}
              />
            </Grid>
          </Grid>
          <PrimaryTextButton
            label="See Balance Mutation"
            onClick={() =>
              history.push({ pathname: "/admin/payment-mutation" })
            }
            endIcon={<ArrowForward />}
          />
          <Grid container justifyContent="space-between" mt={1} mb={3}>
            <Grid item>
              <Grid container columnSpacing={1}>
                <Grid item>
                  <SearchTextInput
                    placeholder="Search"
                    value={tableParams?.search}
                    onChange={e =>
                      handleChangeTableParams(e?.target?.value, "search")
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container columnSpacing={1}>
                <Grid item>
                  <DateRangePicker
                    label="Period"
                    startDate={queryParams?.startDate}
                    handleChangeStartDate={handleChangeStartDate}
                    endDate={queryParams?.endDate}
                    handleChangeEndDate={handleChangeEndDate}
                  />
                </Grid>
                <Grid item>
                  <SelectInput
                    label="Status"
                    value={tableParams?.status || 0}
                    onChange={e =>
                      handleChangeTableParams(e?.target?.value, "status")
                    }
                    options={listStatus}
                    optionKey="id"
                    optionLabel="name"
                    width={150}
                  />
                </Grid>
                <Grid item>
                  <PrimaryButton
                    label="Download CSV"
                    startIcon={<img src={ExcelLogo} alt="logo" />}
                    onClick={downloadTemplate}
                    height={40}
                    size="large"
                    color="#34774C"
                  />
                </Grid>
                <Grid item>
                  <PrimaryButton
                    label="Create Transaction"
                    startIcon={<AddIcon />}
                    onClick={() =>
                      history.push({ pathname: "/admin/payment/add" })
                    }
                    height={40}
                    size="large"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          {dataTable && (
            <InnoTableV2
              isLoading={false}
              columns={columnTable}
              items={dataTable}
              page={tableParams?.page}
              rowsPerPage={tableParams?.size}
              totalPage={tableTotalPage}
              handleChangePage={(_, page) =>
                handleChangeTableParams(page, "page")
              }
              handleChangeRowsPerPage={e =>
                handleChangeTableParams(e?.target?.value, "size")
              }
              isHaveAction
              handleView={customer => {
                history.push(
                  `/admin/payment/${customer.publisher_transaction_id}`,
                  {
                    customer: customer,
                    from: "payment",
                  }
                );
              }}
            />
          )}
        </Container>
      )}
    </Page>
  );
};

const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
const formatFilterDate = date => date.format("YYYY-MM-DD");
const getFilterDate = date => moment(date, "YYYY-MM-DD");
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Transaction",
    link: "/admin/payment",
  },
  {
    label: "Payment",
    active: true,
  },
];
const listStatus = [
  {
    id: 0,
    name: "All",
  },
  {
    id: "approved",
    name: "Approved",
  },
  {
    id: "paid",
    name: "Paid",
  },
  {
    id: "unpaid",
    name: "Unpaid",
  },
  {
    id: "waiting_for_approval",
    name: "Waiting For Approval",
  },
];
const columnTable = [
  {
    name: "composer_name",
    title: "Composer/Author Name",
  },
  {
    name: "invoice_date",
    title: "Date",
    renderText: item => formatDate(item, "DD MMMM YYYY"),
  },
  {
    name: "base_currency_revenue",
    title: "Value",
    renderText: item => (
      <CurrencyDisplay value={item} decimalScale={2} prefix="Rp " />
    ),
  },
  {
    name: "notes",
    title: "Notes",
  },
  {
    name: "status",
    title: "Status",
    renderText: item =>
      listStatus?.find(value => value?.id === item)?.name || "-",
  },
];

export default PaymentPage;
