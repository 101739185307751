import { Chip, Grid } from "@mui/material";
import { useState } from "react";

const ArrayChip = ({ list, max = 4, ...props }) => {
  const [showMore, setShowMore] = useState(false);
  if (!list || list?.length === 0) {
    return "-";
  } else {
    const maxList = list?.slice(0, max);
    const restList = list?.length > max;
    const rest = list?.slice(max - 1);
    return (
      <Grid container spacing={1}>
        {(showMore ? list : maxList).map((item, index) => (
          <Grid item key={index} {...props}>
            <Chip
              label={item?.name || item}
              size="small"
              variant="outlined"
              className={item?.className || ""}
            />
          </Grid>
        ))}
        {restList && !showMore && (
          <Grid item {...props}>
            <Chip
              label={`+${rest.length - 1}`}
              onClick={() => setShowMore(true)}
              size="small"
            />
          </Grid>
        )}
        {showMore && (
          <Grid item {...props}>
            <Chip
              label="Show Less"
              onClick={() => setShowMore(false)}
              size="small"
            />
          </Grid>
        )}
      </Grid>
    );
  }
};

export default ArrayChip;
