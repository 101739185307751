import { Refresh, VisibilityOutlined } from "@material-ui/icons";
import { Grid } from "@mui/material";
import { ModalWarning, PrimaryIconButton } from "components";
import PencilVector from "../../../assets/img/pencilVector.svg";
import TrashVector from "../../../assets/img/trashVector.svg";

const TableAction = ({
  handleView,
  tooltipView,
  handleEdit,
  tooltipEdit,
  handleDelete,
  tooltipDelete,
  deleteConfirmation,
  deleteConfirmationTitle,
  deleteConfirmationContent,
  deleteConfirmationKey,
  handleRestore,
  tooltipRestore,
  restoreConfirmation,
  restoreConfirmationTitle,
  restoreConfirmationContent,
  restoreConfirmationKey,
}) => {
  return (
    <Grid container columnSpacing={1} width={120}>
      {handleView && (
        <Grid item>
          <PrimaryIconButton
            icon={<VisibilityOutlined />}
            onClick={handleView}
            tooltipTitle={tooltipView}
          />
        </Grid>
      )}
      {handleEdit && (
        <Grid item>
          <PrimaryIconButton
            icon={<img src={PencilVector} alt="update-icon" />}
            onClick={handleEdit}
            tooltipTitle={tooltipEdit}
          />
        </Grid>
      )}
      {handleDelete && (
        <Grid item>
          <PrimaryIconButton
            icon={<img src={TrashVector} alt="update-icon" />}
            onClick={
              deleteConfirmation
                ? () =>
                    ModalWarning(
                      deleteConfirmationContent ||
                        `Are you sure you want to delete ${deleteConfirmationKey}`,
                      deleteConfirmationTitle || "Delete Data"
                    ).then(res => res?.isConfirmed && handleDelete())
                : handleDelete
            }
            tooltipTitle={tooltipDelete}
          />
        </Grid>
      )}
      {handleRestore && (
        <Grid item>
          <PrimaryIconButton
            icon={<Refresh />}
            onClick={
              restoreConfirmation
                ? () =>
                    ModalWarning(
                      restoreConfirmationContent ||
                        `Are you sure you want to restore ${restoreConfirmationKey}`,
                      restoreConfirmationTitle || "Restore Data"
                    ).then(res => res?.isConfirmed && handleRestore())
                : handleRestore
            }
            tooltipTitle={tooltipRestore}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default TableAction;
