import NumberFormat from "react-number-format";

const CurrencyDisplay = ({ value, decimalScale, prefix, suffix, ...rest }) => (
  <NumberFormat
    displayType="text"
    value={value || 0}
    thousandSeparator={true}
    decimalScale={decimalScale}
    fixedDecimalScale={true}
    prefix={prefix}
    suffix={suffix}
    {...rest}
  />
);

export default CurrencyDisplay;
