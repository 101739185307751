import {
  Button,
  ButtonGroup,
  Card,
  Container,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Box, Grid } from "@mui/material";
import axios from "axios";
import {
  ArrayChip,
  ArrayTagChip,
  FormLabel,
  ModalError,
  monthData,
  MultipleSelectInput,
  MultipleSelectInputWithTags,
  Page,
  PrimaryButton,
  SearchTextInput,
  SectionLabel,
  SelectInput,
  SkeletonComponent,
  SongUsageTrendChart,
  SongUsageTrendSummarytCard,
  TableCellMultipleRow,
  YearPicker,
} from "components";
import * as FileSaver from "file-saver";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce, isEqual } from "lodash";
import moment from "moment-timezone";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import {
  allOptionsSelected,
  arrayToCommaSeparatedString,
  createAllFilterOption,
  fetchAndFilterOptions,
  filterArrayByKeyValue,
  getErrors,
  handleChangeFilterAll,
  handleChangeFilterAllAfterSearch,
} from "utils";
import * as XLSX from "xlsx";
import { hardBaseUrl } from "../../../services/urlConstant";

const ReviewLagu = () => {
  const classes = useStyles();
  const history = useHistory();
  const typeWeb = localStorage.getItem("typeWeb");
  const userRole = localStorage?.getItem("role");
  const isCaris = typeWeb === "caris";
  const roleSociety = userRole === "society";
  const rolePublisher = userRole === "publisher";
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const urlParams = new URLSearchParams(location.search);
  const paramsSearch = urlParams.get("search");
  const paramsPage = urlParams.get("page");
  const paramsSize = urlParams.get("size");
  const paramsDSP = urlParams.get("dsp_id");
  const paramsPublisher = urlParams.get("publisher_id");
  const paramsOriginalPublisher = urlParams.get("original_publisher_id");
  const paramsMonth = urlParams.get("month");
  const paramsYear = urlParams.get("year");
  const paramsProductType = urlParams.get("product_type");
  const paramsComposer = urlParams.get("composer_id");

  const [queryParams, setQueryParams] = useState({
    dsp_id: paramsDSP || "",
    original_publisher_id: paramsOriginalPublisher || "",
    month: paramsMonth || "",
    year: Number(paramsYear) || moment().format("YYYY"),
    composer_id: paramsComposer || "",
    product_type: paramsProductType || "",
    ...(paramsPublisher && { publisher_id: paramsPublisher || "" }),
  });
  const [tableParams, setTableParams] = useState({
    page: Number(paramsPage) || 1,
    size: Number(paramsSize) || 10,
    search: paramsSearch || "",
  });
  const [optionDspParams, setOptionDspParams] = useState({
    page: 1,
    search: "",
  });
  const [optionComposerParams, setOptionComposerParams] = useState({
    page: 1,
    size: 100,
    search: "",
  });
  const [optionPublisherParams, setOptionPublisherParams] = useState({
    page: 1,
    search: "",
  });
  const [
    optionOriginalPublisherParams,
    setOptionOriginalPublisherParams,
  ] = useState({
    page: 1,
    search: "",
  });
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [selectedMenuChart, setSelectedMenuChart] = useState(1);
  const [selectedDSP, setSelectedDSP] = useState([]);
  const [selectedComposer, setSelectedComposer] = useState([]);
  const [selectedPublisher, setSelectedPublisher] = useState([]);
  const [selectedOriginalPublisher, setSelectedOriginalPublisher] = useState(
    []
  );
  const [selectedMonthFilter, setSelectedMonthFilter] = useState([]);
  const [selectedRevenue, setSelectedRevenue] = useState([]);
  const [optionDsp, setOptionDsp] = useState([]);
  const [optionPublisher, setOptionPublisher] = useState([]);
  const [optionOriginalPublisher, setOptionOriginalPublisher] = useState([]);
  const [optionComposer, setOptionComposer] = useState([]);
  const [optionTypeRevenue, setOptionTypeRevenue] = useState();
  const [tableData, setTableData] = useState([]);
  const [tablePageCount, setTablePageCount] = useState(0);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [chartReport, setChartReport] = useState({
    revenue: 0,
    traffic: 0,
    month: "",
    totalRevenue: 0,
    totalTraffic: 0,
  });

  const prevQueryParams = usePrevious(queryParams);
  const prevDspPagination = usePrevious(optionDspParams.search);
  const prevPublisherPagination = usePrevious(optionPublisherParams.search);
  const prevOriginalPublisherPagination = usePrevious(
    optionOriginalPublisherParams.search
  );
  const prevParamsPublisher = usePrevious(queryParams?.publisher_id);
  const prevOptionOriginalPublisher = usePrevious(optionOriginalPublisher);
  const roleSocietyPublisher = roleSociety && selectedMenuChart === 1;

  const handleChangePageParams = (value, key) => {
    urlParams.set(key, value);
    history.push({ search: urlParams.toString() });
  };
  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      [key]: value,
    }));
    handleChangePageParams(value, key);
  };
  const handleChangeTableParams = (value, key) => {
    setTableParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
    handleChangePageParams(value, key);
    if (key !== "page") {
      handleChangePageParams(1, "page");
    }
  };
  const handleChangeChartReport = (value, key) => {
    setChartReport(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleChangeFilterDSP = (event, option) => {
    const { checked } = event?.target || false;
    setSelectedDSP(
      handleChangeFilterAllAfterSearch({
        selectedList: selectedDSP,
        checked,
        option,
        key: "dsp_id",
        list: optionDsp,
        handleChangeQueryParams,
        params: optionDspParams,
      })
    );
  };
  const handleSearchFilterDSP = value => {
    setOptionDspParams(prev => ({
      ...prev,
      search: value,
      page: 1,
    }));
  };
  const handleChangeFilterPublisher = (event, option) => {
    const { checked } = event?.target || false;
    setSelectedPublisher(
      handleChangeFilterAllAfterSearch({
        selectedList: selectedPublisher,
        checked,
        option,
        key: "publisher_id",
        list: optionPublisher,
        handleChangeQueryParams,
        params: optionPublisherParams,
      })
    );
  };
  const handleSearchFilterPublisher = value => {
    setOptionPublisherParams(prev => ({
      ...prev,
      search: value,
      page: 1,
    }));
  };
  const handleChangeFilterOriginalPublisher = (event, option) => {
    const { checked } = event?.target || false;
    setSelectedOriginalPublisher(
      handleChangeFilterAllAfterSearch({
        selectedList: selectedOriginalPublisher,
        checked,
        option,
        key: "original_publisher_id",
        list: optionOriginalPublisher,
        handleChangeQueryParams,
        params: optionOriginalPublisherParams,
      })
    );
  };
  const handleSearchFilterOriginalPublisher = value => {
    setOptionOriginalPublisherParams(prev => ({
      ...prev,
      search: value,
      page: 1,
    }));
  };
  const handleChangeFilterComposer = (event, option) => {
    const { checked } = event?.target || false;
    setSelectedComposer(prev => {
      const selectedFilter = checked
        ? [...prev, option]
        : filterArrayByKeyValue(prev, option, "composer_id");
      const filterParams = arrayToCommaSeparatedString(
        selectedFilter,
        "composer_id"
      );
      handleChangeQueryParams(filterParams, "composer_id");
      return selectedFilter;
    });
  };
  const handleSearchFilterComposer = value => {
    setOptionComposerParams(prev => ({
      ...prev,
      search: value,
      page: 1,
    }));
  };
  const handleChangeFilterMonth = (event, option) => {
    const { checked } = event?.target || false;
    setSelectedMonthFilter(prev => {
      const newSelectedMonthFilter = checked
        ? [...prev, option]
        : prev.filter(month => month.id !== option.id);
      handleChangeQueryParams(
        newSelectedMonthFilter.map(month => month.id).join(","),
        "month"
      );
      return newSelectedMonthFilter;
    });
  };
  const handleChangeFilterRevenue = (event, option) => {
    const { checked } = event?.target || false;
    setSelectedRevenue(
      handleChangeFilterAll({
        selectedList: selectedRevenue,
        checked,
        option,
        key: "right_type_id",
        list: optionTypeRevenue,
        handleChangeQueryParams,
      })
    );
  };
  const onClickChartAxis = data => {
    if (chartData?.datasets.length > 0) {
      const { dataIndex, axisValue } = data;
      let monthTotalTraffic = 0;
      let monthTotalRevenue = 0;

      chartData?.datasets?.forEach(dataset => {
        monthTotalTraffic += dataset?.listener?.[dataIndex] || 0;
        monthTotalRevenue += dataset?.data?.[dataIndex] || 0;
      });

      const monthName = moment(axisValue, "MMM YY").format("MMMM");

      handleChangeChartReport(monthTotalTraffic, "traffic");
      handleChangeChartReport(monthTotalRevenue, "revenue");
      handleChangeChartReport(monthName, "month");
    }
  };
  const handleView = item =>
    history.push({
      pathname: `/admin/review-lagu/${item?.song_id}`,
      state: {
        song: item,
        queryParams,
      },
    });

  const debounceOptionDSP = useCallback(
    debounce(() => {
      fetchAndFilterOptions({
        headers,
        endpoint: "/dashboard/options/dsp",
        params: optionDspParams,
        label: "name",
        key: "dsp_id",
        setLoading: setLoadingFilter,
        paramsCondition: optionDspParams.search,
        prevPagination: prevDspPagination,
        setOptions: setOptionDsp,
        selectedOptions: selectedDSP,
        setSelectedOptions: setSelectedDSP,
      });
    }, 500),
    [optionDspParams]
  );
  const debounceOptionPublisher = useCallback(
    debounce(() => {
      fetchAndFilterOptions({
        headers,
        endpoint: "/dashboard/options/publisher",
        params: optionPublisherParams,
        label: "name",
        key: "publisher_id",
        setLoading: setLoadingFilter,
        paramsCondition: optionPublisherParams.search,
        prevPagination: prevPublisherPagination,
        setOptions: setOptionPublisher,
        selectedOptions: selectedPublisher,
        setSelectedOptions: setSelectedPublisher,
      });
    }, 500),
    [optionPublisherParams]
  );
  const getOriginalPublisherList = () =>
    fetchAndFilterOptions({
      headers,
      endpoint: "/dashboard/options/original_publisher",
      params: optionOriginalPublisherParams,
      label: "name",
      key: "original_publisher_id",
      setLoading: setLoadingFilter,
      paramsCondition: optionOriginalPublisherParams.search,
      prevPagination: prevOriginalPublisherPagination,
      setOptions: setOptionOriginalPublisher,
      selectedOptions: selectedOriginalPublisher,
      setSelectedOptions: setSelectedOriginalPublisher,
    });
  const debounceOptionOriginalPublisher = useCallback(
    debounce(() => {
      getOriginalPublisherList();
    }, 500),
    [optionOriginalPublisherParams]
  );
  const getComposerList = async () => {
    const url = `${hardBaseUrl}/dashboard/options/composer`;
    const params = optionComposerParams;
    try {
      setLoadingFilter(true);
      const res = await axios.get(url, { headers, params });
      const resData = res?.data?.data;
      setOptionComposer(resData);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingFilter(false);
    }
  };
  const debounceOptionComposer = useCallback(
    debounce(() => {
      getComposerList();
    }, 500),
    [optionComposerParams]
  );
  const getDataTable = async () => {
    const params = {
      ...queryParams,
      ...tableParams,
    };
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/publisher/song-usage`, {
        headers,
        params,
      });
      const { data, meta } = res?.data;
      setTableData(data || []);
      const pageCount = getTotalPage(meta?.total, tableParams?.size);
      setTablePageCount(pageCount || 1);
      setLoadingPage(false);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams, tableParams]
  );
  const getChartData = async () => {
    const url = `${hardBaseUrl}/publisher/dashboard/chart`;
    const options = {
      headers,
      params: queryParams,
    };
    try {
      setLoadingPage(true);
      const res = await axios.get(url, options);
      const { data } = res?.data || [];
      if (data) {
        const labels = data[0].chart.map(item =>
          moment(item.date).format("MMM YY")
        );
        const datasets = data.map(item => ({
          label: item?.dsp?.name,
          data: item?.chart.map(chartItem => chartItem?.royalty),
          listener: item?.chart.map(chartItem => chartItem?.listener),
          color: item?.dsp?.color,
          showMark: false,
        }));
        setChartData({
          labels,
          datasets,
        });

        const totals = data.reduce(
          (acc, item) => {
            acc.revenue += item.revenue;
            acc.traffic += item.traffic;
            return acc;
          },
          { revenue: 0, traffic: 0 }
        );
        setChartReport(currentState => ({
          ...currentState,
          totalRevenue: totals.revenue,
          totalTraffic: totals.traffic,
        }));
      } else {
        setChartData({
          labels: [],
          datasets: [],
        });
        setChartReport({
          revenue: 0,
          traffic: 0,
          month: "",
          totalRevenue: 0,
          totalTraffic: 0,
        });
      }
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const getDownloadSongUsage = async type => {
    try {
      setLoadingPage(true);
      const params = {
        ...queryParams,
        ...tableParams,
        size: -1,
        type: type,
      };
      const options = {
        headers,
        params,
      };
      const res = await axios.get(
        `${hardBaseUrl}/publisher/song-usage/download`,
        options
      );
      return res?.data?.data || [];
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const getOptionRevenue = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/right_types `, { headers });
      const { data } = res?.data;
      const updatedOptionsList = [
        createAllFilterOption({
          label: "right_type_name",
          key: "right_type_id",
        }),
        ...data?.map(item => ({
          ...item,
          right_type_id: item?.id,
        })),
      ];
      setOptionTypeRevenue(updatedOptionsList);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const handleDownloadPDF = () => {
    getDownloadSongUsage("pdf").then(result =>
      history.push({
        pathname: "/admin/review-lagu/print-report",
        state: {
          dataTable: result,
          chartData,
          chartReport,
          selectedDSP,
          roleSocietyPublisher,
          isCaris,
        },
      })
    );
  };
  const handleDownloadXLSX = async () => {
    const fileName = `Report Song Usage ${selectedDSP?.name ||
      ""} ${queryParams?.year || ""}`;
    const dataToPrint = await getDownloadSongUsage("csv");
    const reConstructData = (dataToPrint || [])?.map(item => ({
      "Song Title": item?.SongTitle,
      ISWC: item?.ISWC,
      Composer: item?.Composer,
      Month: item?.Month,
      Traffic: item?.Traffic,
      Revenue: item?.OriginalCurrencyRevenue,
      ...(isCaris && {
        "Composer Revenue": item?.BaseCurrencyComposerRevenue,
        "Publisher Revenue": item?.BaseCurrencyPublisherRevenue,
      }),
      "Product Type": item?.ProductType,
    }));
    const ws = XLSX.utils.json_to_sheet(reConstructData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "xlsx" });
    FileSaver.saveAs(data, fileName + ".xlsx");
  };

  useEffect(() => {
    const pageParams =
      paramsDSP ||
      paramsPublisher ||
      paramsOriginalPublisher ||
      paramsMonth ||
      paramsYear ||
      paramsProductType;
    if (roleSociety) {
      getOptionRevenue();
    }
    if (paramsMonth) {
      setSelectedMonthFilter(
        monthData.filter(option => paramsMonth.includes(option.id))
      );
    }
    if (pageParams) {
      getChartData();
      if (queryParams?.publisher_id) {
        setOptionOriginalPublisherParams(prev => ({
          ...prev,
          publisher_id: queryParams?.publisher_id,
        }));
      }
    }
  }, []);
  useEffect(() => {
    debounceOptionDSP();
    return () => {
      debounceOptionDSP.cancel();
    };
  }, [optionDspParams, debounceOptionDSP]);
  useEffect(() => {
    debounceOptionPublisher();
    return () => {
      debounceOptionPublisher.cancel();
    };
  }, [optionPublisherParams, debounceOptionPublisher]);
  useEffect(() => {
    if (optionOriginalPublisherParams?.publisher_id) {
      if (optionOriginalPublisherParams?.search) {
        debounceOptionOriginalPublisher();
        return () => {
          debounceOptionOriginalPublisher.cancel();
        };
      } else {
        getOriginalPublisherList();
      }
    }
  }, [
    optionOriginalPublisherParams?.publisher_id,
    optionOriginalPublisherParams,
    debounceOptionOriginalPublisher,
  ]);
  useEffect(() => {
    debounceOptionComposer();
    return () => {
      debounceOptionComposer.cancel();
    };
  }, [optionComposerParams, debounceOptionComposer]);
  useEffect(() => {
    if (prevQueryParams && !isEqual(prevQueryParams, queryParams)) {
      getChartData();
      debounceDataTable();
      if (queryParams?.publisher_id) {
        setOptionOriginalPublisherParams(prev => ({
          ...prev,
          publisher_id: queryParams?.publisher_id,
        }));
      }
    } else {
      debounceDataTable();
    }

    return () => {
      debounceDataTable.cancel();
    };
  }, [queryParams, tableParams, debounceDataTable]);
  useEffect(() => {
    if (!selectedPublisher.length) {
      setSelectedOriginalPublisher([]);
      if (
        prevParamsPublisher &&
        !isEqual(prevParamsPublisher, queryParams?.publisher_id)
      ) {
        handleChangeQueryParams("", "original_publisher_id");
      }
      return;
    }
    const isPaginationEqual = isEqual(
      prevOriginalPublisherPagination,
      optionOriginalPublisherParams?.search
    );
    if (!isPaginationEqual) return;

    if (!optionOriginalPublisherParams?.search || paramsOriginalPublisher) {
      const filteredOptions = optionOriginalPublisher.filter(
        item => item?.original_publisher_id !== "all"
      );
      const updatedSelected = selectedOriginalPublisher
        .filter(item => item?.original_publisher_id !== "all")
        .filter(item =>
          filteredOptions.some(
            option =>
              option.original_publisher_id === item.original_publisher_id
          )
        );

      const selectedId = paramsOriginalPublisher
        ?.split(",")
        ?.map(id => id.trim());
      const selected = filteredOptions.filter(option =>
        selectedId?.includes(option.original_publisher_id.toString())
      );
      const allSelected = allOptionsSelected({
        list: filteredOptions,
        key: "original_publisher_id",
        selectedFilter: paramsOriginalPublisher
          ? selected
          : selectedOriginalPublisher,
      });
      if (
        prevOptionOriginalPublisher &&
        !isEqual(prevOptionOriginalPublisher, optionOriginalPublisher)
      ) {
        handleChangeQueryParams(
          arrayToCommaSeparatedString(updatedSelected, "original_publisher_id")
        );
      }
      setSelectedOriginalPublisher(
        paramsOriginalPublisher
          ? allSelected
            ? optionOriginalPublisher
            : selected
          : allSelected
          ? optionOriginalPublisher
          : updatedSelected
      );
    }
  }, [
    selectedPublisher,
    optionOriginalPublisherParams,
    optionOriginalPublisher,
  ]);
  useEffect(() => {
    if (optionDsp && optionDsp.length > 0 && paramsDSP) {
      const selectedId = paramsDSP?.split(",")?.map(id => id.trim());
      const selected = optionDsp.filter(option =>
        selectedId.includes(option.dsp_id.toString())
      );
      setSelectedDSP(
        allOptionsSelected({
          list: optionDsp,
          key: "dsp_id",
          selectedFilter: selected,
        })
          ? optionDsp
          : selected
      );
    }
  }, [optionDsp]);
  useEffect(() => {
    if (optionPublisher && optionPublisher.length > 0 && paramsPublisher) {
      const selectedId = paramsPublisher?.split(",")?.map(id => id.trim());
      const selected = optionPublisher.filter(option =>
        selectedId.includes(option.publisher_id.toString())
      );
      setSelectedPublisher(
        allOptionsSelected({
          list: optionPublisher,
          key: "publisher_id",
          selectedFilter: selected,
        })
          ? optionPublisher
          : selected
      );
    }
  }, [optionPublisher]);
  const filterConfig = filterConfigList({
    roleSociety,
    optionTypeRevenue,
    selectedRevenue,
    handleChangeFilterRevenue,
    selectedDSP,
    optionDsp,
    handleChangeFilterDSP,
    optionDspParams,
    handleSearchFilterDSP,
    isCaris,
    selectedMenuChart,
    selectedComposer,
    optionComposer,
    handleChangeFilterComposer,
    optionComposerParams,
    handleSearchFilterComposer,
    selectedPublisher,
    optionPublisher,
    handleChangeFilterPublisher,
    optionPublisherParams,
    handleSearchFilterPublisher,
    rolePublisher,
    selectedMonthFilter,
    handleChangeFilterMonth,
    selectedOriginalPublisher,
    optionOriginalPublisher,
    handleChangeFilterOriginalPublisher,
    optionOriginalPublisherParams,
    handleSearchFilterOriginalPublisher,
    queryParams,
    handleChangeQueryParams,
  });

  return (
    <Page className={classes?.root} title="Song Usage Review">
      <Container maxWidth={false}>
        {loadingPage ? (
          <SkeletonComponent variant="wave" />
        ) : (
          <Fragment>
            <HeaderTitle title="Song Usage" breadcrumbData={breadcrumbData} />
            <Divider className={classes?.divider} />
            {roleSociety && (
              <Box mb={2}>
                <ButtonGroup>
                  {menuChart?.map(({ title, id }) => {
                    const selected = id === selectedMenuChart;
                    return (
                      <Button
                        className={
                          selected
                            ? classes?.groupButtonSelected
                            : classes?.groupButton
                        }
                        key={id}
                        onClick={() => {
                          setSelectedMenuChart(id);
                        }}
                      >
                        {title}
                      </Button>
                    );
                  })}
                </ButtonGroup>
              </Box>
            )}
            <Card className={classes?.filterArea}>
              <Grid container rowSpacing={1} columnSpacing={2} maxWidth={1084}>
                {filterConfig?.map((item, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    key={index}
                    maxWidth={250}
                  >
                    <FormLabel label={item?.label} />
                    {item?.type === "checkboxWithTag" ? (
                      <MultipleSelectInputWithTags
                        placeholder={item?.placeholder}
                        value={item?.value}
                        textValue={item?.textValue}
                        options={item?.options}
                        optionKey={item?.optionKey}
                        optionLabel={item?.optionLabel}
                        onChange={item?.onChange}
                        handleRemove={item?.handleRemove}
                        search={item?.search}
                        searchValue={item?.searchValue}
                        onChangeSearch={item?.onChangeSearch}
                        loading={loadingFilter}
                        disabled={item?.disabled || loadingFilter}
                        group={item?.group}
                      />
                    ) : item?.type === "checkbox" ? (
                      <MultipleSelectInput
                        placeholder={item?.placeholder}
                        value={item?.value}
                        textValue={item?.textValue}
                        options={item?.options}
                        optionKey={item?.optionKey}
                        optionLabel={item?.optionLabel}
                        onChange={item?.onChange}
                        disabled={
                          item?.disabled ? item?.disabled : loadingFilter
                        }
                        width={255}
                      />
                    ) : item?.type === "yearPicker" ? (
                      <YearPicker
                        onChange={item?.onChange}
                        value={item?.value}
                      />
                    ) : (
                      <SelectInput
                        value={item?.value}
                        placeholder={item?.placeholder}
                        onChange={item?.onChange}
                        options={item?.options}
                        optionKey={item?.optionKey}
                        optionLabel={item?.optionLabel}
                        width={255}
                      />
                    )}
                  </Grid>
                ))}
              </Grid>
            </Card>
            <Box mt="20px">
              <SectionLabel title="Trend of Song Usage" />
            </Box>
            <Box
              border="1px solid #ebebeb"
              borderRadius="5px"
              padding="50px 20px"
              my={3}
            >
              <Box mb={2}>
                {chartData?.datasets.length > 0 && selectedDSP?.length > 0 && (
                  <ArrayTagChip
                    selectionsList={
                      selectedDSP.some(item => item.dsp_id === "all")
                        ? [{ dsp_id: "all", name: "All" }]
                        : selectedDSP
                    }
                    value="name"
                  />
                )}
              </Box>
              <SongUsageTrendChart
                chartData={chartData}
                onAxisClick={(_, data) => onClickChartAxis(data)}
              />
            </Box>
            <SongUsageTrendSummarytCard chartReport={chartReport} />
            <Grid container justifyContent="space-between" my={2}>
              <Grid item>
                <SearchTextInput
                  placeholder="Song title, Composer"
                  onChange={e =>
                    handleChangeTableParams(e?.target?.value, "search")
                  }
                  value={tableParams?.search}
                />
              </Grid>
              <Grid item>
                <Grid container columnSpacing={2}>
                  {downloadButtonList({
                    handleDownloadPDF,
                    handleDownloadXLSX,
                  })?.map((item, key) => (
                    <Grid item key={key}>
                      <PrimaryButton
                        label={`Download ${item?.caption}`}
                        onClick={item?.onClick}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Fragment>
        )}
        <Box position="relative" display={loadingPage ? "none" : "block"}>
          <InnoTableV2
            columns={columnTable({
              roleSocietyPublisher,
              classes,
              isCaris,
              handleView,
            })}
            onRequestSort={(sortBy, sort) =>
              handleChangeTableParams(`${sortBy} ${sort}`, "sort")
            }
            items={tableData || []}
            isLoading={false}
            page={tableParams?.page}
            rowsPerPage={tableParams?.size}
            totalPage={tablePageCount}
            handleChangePage={(_, data) => {
              handleChangeTableParams(data, "page");
            }}
            handleChangeRowsPerPage={e => {
              handleChangeTableParams(e?.target?.value, "size");
            }}
          />
        </Box>
      </Container>
    </Page>
  );
};

const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
const useStyles = makeStyles(() => ({
  root: {
    padding: "24px 0",
  },
  divider: {
    margin: "16px 0",
    borderTop: "1px solid #e1e1e1",
  },
  filterArea: {
    backgroundColor: "#F9FAFB",
    padding: "20px 10px",
  },
  chartCard: {
    border: "1px solid #ebebeb",
    borderRadius: "5px",
    padding: "50px 20px",
  },
  highlightText: {
    fontWeight: "bold",
  },
  groupButton: {
    textTransform: "none",
    border: "1px solid #D1D5DC",
    color: "#111827",
    height: 42,
    "&:hover": {
      color: "#111827",
    },
  },
  groupButtonSelected: {
    backgroundColor: "#D1D5DC",
    color: "#111827",
    textTransform: "none",
    border: "1px solid #D1D5DC",
    height: 42,
    "&:hover": {
      backgroundColor: "#D1D5DC",
      color: "#111827",
    },
  },
}));
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Song Usage",
    active: true,
  },
];
const productTypeList = [
  {
    value: 0,
    name: "None",
  },
  {
    value: "flat",
    name: "Flat",
  },
  {
    value: "recoupable",
    name: "Recoupable",
  },
];
const menuChart = [
  {
    id: 1,
    title: "Publisher",
  },
  {
    id: 2,
    title: "Author/Composer",
  },
];
const dspFilterConfig = ({
  value,
  options,
  onChange,
  params,
  onChangeSearch,
}) => ({
  label: "DSP",
  textValue: "DSP",
  value,
  options,
  optionKey: "dsp_id",
  optionLabel: "name",
  onChange,
  search: true,
  onChangeSearch,
  searchValue: params?.search,
  type: "checkboxWithTag",
});
const authorComposerFilterConfig = ({
  value,
  options,
  onChange,
  params,
  onChangeSearch,
}) => ({
  label: "Author/Composer",
  textValue: "Author/Composer",
  value,
  options,
  optionKey: "composer_id",
  optionLabel: "sure_name",
  onChange,
  search: true,
  onChangeSearch,
  searchValue: params?.search,
  type: "checkboxWithTag",
});
const publisherFilterConfig = ({
  value,
  options,
  onChange,
  params,
  onChangeSearch,
  rolePublisher,
}) => ({
  label: "Publisher",
  textValue: "Publisher",
  value,
  options,
  optionKey: "publisher_id",
  optionLabel: "name",
  onChange,
  search: true,
  onChangeSearch,
  searchValue: params?.search,
  type: "checkboxWithTag",
  group: rolePublisher,
});
const originalPublisherFilterConfig = ({
  value,
  options,
  onChange,
  params,
  onChangeSearch,
  watchParams,
}) => ({
  label: "Original Publisher",
  textValue: "Original Publisher",
  value,
  options,
  optionKey: "original_publisher_id",
  optionLabel: "name",
  onChange,
  search: true,
  onChangeSearch,
  searchValue: params?.search,
  type: "checkboxWithTag",
  disabled: watchParams?.length === 0,
});
const ousideDspFilterConfig = {
  label: "Outside DSP",
  value: 0,
  options: [{ id: 0, label: "None" }],
  optionKey: "id",
  optionLabel: "label",
};
const monthFilterConfig = (selectedMonthFilter, handleChangeFilterMonth) => ({
  label: "Month",
  placeholder: "Month",
  value: selectedMonthFilter,
  textValue: arrayToCommaSeparatedString(selectedMonthFilter, "shortName"),
  options: monthData,
  optionKey: "id",
  optionLabel: "fullName",
  onChange: handleChangeFilterMonth,
  type: "checkbox",
});
const yearFilterConfig = (queryParams, handleChangeQueryParams) => ({
  label: "Year",
  value: queryParams?.year,
  onChange: year => handleChangeQueryParams(year, "year"),
  type: "yearPicker",
});
const productTypeFilterConfig = (queryParams, handleChangeQueryParams) => ({
  label: "Product Type",
  value: queryParams?.product_type || 0,
  options: productTypeList,
  optionKey: "value",
  optionLabel: "name",
  onChange: event =>
    handleChangeQueryParams(event?.target?.value || "", "product_type"),
});
const revenueSocietyFilterConfig = (
  optionTypeRevenue,
  selectedRevenue,
  handleChangeFilterRevenue
) => ({
  label: "Revenue",
  textValue: "Revenue",
  value: selectedRevenue,
  options: optionTypeRevenue,
  optionKey: "right_type_id",
  optionLabel: "right_type_name",
  onChange: handleChangeFilterRevenue,
  type: "checkbox",
});
const filterConfigList = ({
  roleSociety,
  optionTypeRevenue,
  selectedRevenue,
  handleChangeFilterRevenue,
  selectedDSP,
  optionDsp,
  handleChangeFilterDSP,
  optionDspParams,
  handleSearchFilterDSP,
  isCaris,
  selectedMenuChart,
  selectedComposer,
  optionComposer,
  handleChangeFilterComposer,
  optionComposerParams,
  handleSearchFilterComposer,
  selectedPublisher,
  optionPublisher,
  handleChangeFilterPublisher,
  optionPublisherParams,
  handleSearchFilterPublisher,
  rolePublisher,
  selectedMonthFilter,
  handleChangeFilterMonth,
  selectedOriginalPublisher,
  optionOriginalPublisher,
  handleChangeFilterOriginalPublisher,
  optionOriginalPublisherParams,
  handleSearchFilterOriginalPublisher,
  queryParams,
  handleChangeQueryParams,
}) => [
  ...(roleSociety
    ? [
        revenueSocietyFilterConfig(
          optionTypeRevenue,
          selectedRevenue,
          handleChangeFilterRevenue
        ),
      ]
    : []),
  dspFilterConfig({
    value: selectedDSP,
    options: optionDsp,
    onChange: handleChangeFilterDSP,
    params: optionDspParams,
    onChangeSearch: handleSearchFilterDSP,
  }),
  ...(isCaris ? [ousideDspFilterConfig] : []),
  ...(roleSociety && selectedMenuChart === 2
    ? [
        authorComposerFilterConfig({
          value: selectedComposer,
          options: optionComposer,
          onChange: handleChangeFilterComposer,
          params: optionComposerParams,
          onChangeSearch: handleSearchFilterComposer,
        }),
      ]
    : [
        publisherFilterConfig({
          value: selectedPublisher,
          options: optionPublisher,
          onChange: handleChangeFilterPublisher,
          params: optionPublisherParams,
          onChangeSearch: handleSearchFilterPublisher,
          rolePublisher,
        }),
      ]),
  ...(roleSociety
    ? [monthFilterConfig(selectedMonthFilter, handleChangeFilterMonth)]
    : [
        originalPublisherFilterConfig({
          value: selectedOriginalPublisher,
          options: optionOriginalPublisher,
          onChange: handleChangeFilterOriginalPublisher,
          params: optionOriginalPublisherParams,
          onChangeSearch: handleSearchFilterOriginalPublisher,
          watchParams: selectedPublisher,
        }),
      ]),
  yearFilterConfig(queryParams, handleChangeQueryParams),
  ...(roleSociety
    ? []
    : [
        authorComposerFilterConfig({
          value: selectedComposer,
          options: optionComposer,
          onChange: handleChangeFilterComposer,
          params: optionComposerParams,
          onChangeSearch: handleSearchFilterComposer,
        }),
        monthFilterConfig(selectedMonthFilter, handleChangeFilterMonth),
        productTypeFilterConfig(queryParams, handleChangeQueryParams),
      ]),
];
const columnTable = ({
  roleSocietyPublisher,
  classes,
  isCaris,
  handleView,
}) => [
  {
    name: "all",
    title: "",
    renderText: item => (
      <PrimaryButton
        label="See Detail"
        onClick={() => handleView(item)}
        width={110}
      />
    ),
  },
  {
    name: "title",
    title: "Song Title",
    useSort: true,
    sort_key: "song_title",
  },
  {
    name: "iswc_code",
    title: "ISWC",
    useSort: true,
    sort_key: "iswc_code",
  },
  {
    name: roleSocietyPublisher ? "publisher_name" : "all",
    title: roleSocietyPublisher ? "Publisher" : "Composer/Author",
    renderText: item => {
      if (roleSocietyPublisher) {
        return (
          <ArrayChip
            list={item?.map(publisher => publisher?.publisher_name)}
            max={2}
          />
        );
      } else {
        const listValue = item?.composers?.map(
          ({ name, is_on_this_publisher }) => ({
            name,
            className: is_on_this_publisher ? classes?.highlightText : "",
          })
        );
        return <ArrayChip list={listValue} max={2} />;
      }
    },
  },
  {
    name: "summaries",
    title: "Traffic",
    renderText: item => (
      <TableCellMultipleRow list={item} itemKey="listener" type="number" />
    ),
    useSort: true,
    sort_key: "stream",
  },
  {
    name: "summaries",
    title: "Revenue",
    sort_key: "base_currency_revenue",
    useSort: true,
    renderText: item => (
      <TableCellMultipleRow
        list={item}
        itemKey="base_currency_revenue"
        type="number"
        prefix="Rp "
      />
    ),
  },
  ...(isCaris
    ? [
        {
          name: "summaries",
          title: "Composer Revenue",
          useSort: true,
          sort_key: "base_currency_composer_revenue",
          renderText: item => (
            <TableCellMultipleRow
              list={item}
              itemKey="base_currency_composer_revenue"
              type="number"
              prefix="Rp "
            />
          ),
        },
        {
          name: "summaries",
          title: "Publisher Revenue",
          useSort: true,
          sort_key: "base_currency_publisher_revenue",
          renderText: item => (
            <TableCellMultipleRow
              list={item}
              itemKey="base_currency_publisher_revenue"
              type="number"
              prefix="Rp "
            />
          ),
        },
      ]
    : []),
  {
    name: "summaries",
    title: "Product Type",
    renderText: item => (
      <TableCellMultipleRow list={item} itemKey="product_type" />
    ),
  },
];
const downloadButtonList = ({ handleDownloadPDF, handleDownloadXLSX }) => [
  {
    caption: "PDF",
    onClick: handleDownloadPDF,
  },
  {
    caption: "XLSX",
    onClick: handleDownloadXLSX,
  },
];

export default ReviewLagu;
