import {
  Breadcrumbs,
  Button,
  ButtonGroup,
  Container,
  Divider,
  Grid,
  Typography,
  Box,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { Page } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import moment from "moment";
import Detail from "../Detail";
import DialogNonAktifkanKontrak from "../DialogNonAktifkanKontrak";
import DokumenPendukung from "../DokumenPendukung";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(() => ({
  root: {
    padding: "1rem 0px"
  },
  boxContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
  },
  buttonGroup: {
    textTransform: "none",
    border: "1px solid #D1D5DC",
    padding: "10px 16px",
  },
  buttonSelect: {
    backgroundColor: "#E4E7EB",
    color: "black",
    "&:hover": {
      backgroundColor: "#E4E7EB",
      color: "black",
    },
  },
  buttonNonActive: {
    textTransform: "none",
    backgroundColor: "#111827",
    color: "white",
    padding: "10px 28px",
    borderRadius: "6px",
    width: "160px",
    height: "40px",
  },
  buttonUpdateContract: {
    textTransform: "none",
    backgroundColor: "#111827",
    color: "white",
    padding: "10px 28px",
    borderRadius: "6px",
    width: "160px",
    height: "40px",
    marginLeft: "10px",
    whiteSpace: "nowrap",
  },
  divider: {
    margin: "1rem 0px"
  },
  typographyDetail: {
    lineHeight: "20px",
    fontSize: "14px",
    fontWeight: "500",
  },
  typographyButton: {
    fontSize: "14px",
    fontWeight: "500",
    color: "white",
  },
}));


const DetailKontrak = () => {
  const { state } = useLocation();

  const classes = useStyles();

  const [valueTab, setValueTab] = useState(1);
  const [dataComposer, setDataComposer] = useState([]);

  const [idPerformer, setIdPerformer] = useState(null);
  const [performerName, setPerformerName] = useState("");

  const [openDialog, setOpenDialog] = useState(false);
  const [currentDate, setCurrentDate] = useState(moment().toString());
  const [radioDateValue, setRadioDateValue] = useState(null);
  const [contractNumber, setContractNumber] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("");
  const [publisherName, setPublisherName] = useState("");
  const [idPublisher, setIdPublisher] = useState("");
  const [description, setDescription] = useState("");
  const [songTitle, setSongTitle] = useState("");
  const [ownershipPercentage, setOwnershipPercentage] = useState("");
  const [iswcCode, setIswcCode] = useState("");

  const handleOpenNonAktifDialog = () => setOpenDialog(true);
  const handleCloseNonAKtifDialog = () => setOpenDialog(false);

  const handleChangeDateNonAktif = event => {
    if (event.target.value === "3day") {
      setRadioDateValue("3day");
      setCurrentDate(new Date().getTime() + 3 * 24 * 60 * 60 * 1000);
    } else if (event.target.value === "1week") {
      setRadioDateValue("1week");
      setCurrentDate(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
    } else if (event.target.value === "2week") {
      setRadioDateValue("2week");
      setCurrentDate(new Date().getTime() + 14 * 24 * 60 * 60 * 1000);
    }
  };

  const handleChangeDate = date => {
    setCurrentDate(date);
  };

  const onSubmitNonAktif = () => {
    const date = new Date(currentDate);
    const year = date.getFullYear();

    const month = ("0" + (date.getMonth() + 1)).slice(-2);

    const day = ("0" + date.getDate()).slice(-2);
    const dateString = `${year}-${month}-${day}`;

    const payload = {
      is_active_flag: false,
      inactive_at: dateString,
    };

    const url = `${hardBaseUrl}/publisher/contract/status/original-publisher/${state.customer.contract_id}`;
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .put(url, payload, { headers })
      .then(() => {
        Swal.fire({
          title: "Success",
          text: "Status Kontrak Berhasil Diubah",
          icon: "success",
        });
        handleCloseNonAKtifDialog();
      })
      .catch(() => {
        Swal.fire({
          title: "Error",
          text: "Terjadi Kesalahan",
          icon: "error",
        });
      });
  };

  const handleClickTab1 = () => {
    setValueTab(1);
  };

  const handleClickTab2 = () => {
    setValueTab(2);
  };

  const getSongResponse = () => {
    const url = `${hardBaseUrl}/publisher/song`;
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(url, config)
      .then(res => {
        const dataComposer = res.data.data.filter(
          composer => composer.song_id === state.customer.song.song_id
        );

        if (dataComposer.length > 0) {
          setIdPerformer(dataComposer[0].performer_id);
          setDataComposer(dataComposer[0].song_composer);
        }
      })
      .catch(() => { });
  };

  const getPerformerList = () => {
    const url = `${hardBaseUrl}/publisher/performer`;
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(url, config)
      .then(res => {
        const dataPerformer = res.data.data.filter(
          performer => performer.performer_id === idPerformer
        );

        if (dataPerformer.length > 0) {
          setPerformerName(dataPerformer[0].first_name);
        }
      })
      .catch(() => { });
  };

  useEffect(() => {
    getSongResponse();
    getPerformerList();
  }, [dataComposer, idPerformer]);

  const getResponseDetail = () => {
    const url = `${hardBaseUrl}/publisher/contract/original-publisher/${state.customer.contract_id}`;
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(url, config)
      .then(res => {
        setContractNumber(res.data.data.contract.contract_number);
        setStatus(res.data.data.contract.is_active_flag);
        setStartDate(moment(res.data.data.contract.start_date, "DD-MM-YYYY").format("DD MMM YYYY"));
        setEndDate(moment(res.data.data.contract.end_date, "DD-MM-YYYY").format("DD MMM YYYY"));
        setPublisherName(res.data.data.contract.publisher);
        setIdPublisher(res.data.data.contract.id_publisher);
        setSongTitle(res.data.data.songs[0].title);
        setPerformerName(res.data.data.songs[0].performer);
        setIswcCode(res.data.data.songs[0].iswc_code);
        setDescription(res.data.data.contract.description);
        setOwnershipPercentage(res.data.data.songs[0].ownership);
        setDataComposer(res.data.data.songs[0].composers);
      })
      .catch(() => { });
  };
  useEffect(() => {
    getResponseDetail();
  }, []);

  return (
    <Page className={classes.root} title="Detail Contract Original Publisher">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography className={classes.textContract}>
                Contract Detail
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>Contract</Typography>

              <Typography className={classes.breadCrumbsActive}>
                Contract Detail
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Box className={classes.boxContainer}>
          <Box>
            <ButtonGroup aria-label="outlined primary button group">
              <Button
                className={`${classes.buttonGroup} ${valueTab === 1 ? classes.buttonSelect : ""}`}
                onClick={handleClickTab1}
              >
                <ThemeProvider theme={theme}>
                  <Typography className={classes.typographyDetail}>Detail</Typography>
                </ThemeProvider>
              </Button>
              <Button
                className={`${classes.buttonGroup} ${valueTab === 2 ? classes.buttonSelect : ""}`}
                onClick={handleClickTab2}
              >
                <ThemeProvider theme={theme}>
                  <Typography className={classes.typographyDetail}>Supporting Document</Typography>
                </ThemeProvider>
              </Button>
            </ButtonGroup>
          </Box>

          <Box>
            <Button onClick={handleOpenNonAktifDialog} className={classes.buttonNonActive}>
              <ThemeProvider theme={theme}>
                <Typography className={classes.typographyButton}>Non-Active</Typography>
              </ThemeProvider>
            </Button>
            <Button
              className={classes.buttonUpdateContract}
              component={RouterLink}
              to={{
                pathname: `/admin/kontrak-original-publisher/edit/${state.customer.contract_id}`,
                state: {
                  id: state.customer.contract_id,
                },
              }}
            >
              <ThemeProvider theme={theme}>
                <Typography className={classes.typographyButton}>Update Contract</Typography>
              </ThemeProvider>
            </Button>
          </Box>
        </Box>

        <Divider className={classes.divider} />

        {valueTab === 1 ? (
          <Detail
            noKontrak={contractNumber}
            tglMulai={startDate}
            publisher={publisherName}
            status={status}
            tglSelesai={endDate}
            idPublisher={idPublisher}
            keterangan={description}
            judul={songTitle}
            kepemilikan={ownershipPercentage}
            performer={performerName}
            dataPencipta={dataComposer}
            kodeIsrc={iswcCode}
          />
        ) : (
          <DokumenPendukung idKontrak={state.customer.contract_id} />
        )}
      </Container>
      <DialogNonAktifkanKontrak
        open={openDialog}
        onClose={handleCloseNonAKtifDialog}
        dateValue={currentDate}
        handleChange={handleChangeDateNonAktif}
        radioValue={radioDateValue}
        onSubmit={onSubmitNonAktif}
        handleChangeDate={handleChangeDate}
      />
    </Page>
  );
};

export default DetailKontrak;
