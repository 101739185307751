import { Box, Grid, styled, Tooltip } from "@mui/material";
import { formatDate } from "utils";
import { CurrencyDisplay } from "components";

const TableCellMultipleRow = ({
  list,
  itemKey,
  type,
  prefix,
  prefixKey,
  tooltipKey,
  suffix,
}) => {
  const getDefaultValue = value => (typeof value === "string" ? "-" : 0);
  return (
    <Grid container direction="column">
      {list?.length > 0 ? (
        (list || []).map((item, index) => (
          <Grid item key={index}>
            <Tooltip title={item?.[tooltipKey]} placement="bottom">
              {list?.length > 1 && index > 0 && <CellBorder />}
              {type === "number" ? (
                <CurrencyDisplay
                  value={item?.[itemKey] || 0}
                  decimalScale={prefix && item?.[itemKey] ? 2 : 0}
                  prefix={prefixKey ? `${item?.[prefixKey]} ` : prefix}
                  suffix={suffix}
                />
              ) : type === "date" ? (
                formatDate(item?.[itemKey])
              ) : (
                <span>
                  {item?.[itemKey] || getDefaultValue(item?.[itemKey])}
                </span>
              )}
            </Tooltip>
          </Grid>
        ))
      ) : (
        <Grid item>
          {type === "number" ? (prefix || prefixKey ? "Rp 0.00" : 0) : "-"}
        </Grid>
      )}
    </Grid>
  );
};

const CellBorder = styled(Box)({
  margin: "8px -16px",
  height: "1px",
  backgroundColor: "#e0e0e0",
});
export default TableCellMultipleRow;
