import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Box, Container, Grid } from "@mui/material";
import axios from "axios";
import {
  ModalError,
  MonthYearRangePicker,
  Page,
  PrimaryButton,
  SearchTextInput,
  SkeletonComponent,
  TableCellMultipleRow,
} from "components";
import html2canvas from "html2canvas";
import { InnoTableV2 } from "inno-ui";
import { jsPDF } from "jspdf";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { getErrors } from "utils";
import { hardBaseUrl } from "../../../services/urlConstant";

function Report() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const previewReportRef = useRef(null);
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const urlParams = new URLSearchParams(location.search);
  const paramsSearch = urlParams.get("search");
  const paramsPage = urlParams.get("page");
  const paramsSize = urlParams.get("size");
  const paramsStartDate = urlParams.get("startDate");
  const paramsEndDate = urlParams.get("endDate");

  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: Number(paramsPage) || 1,
    size: Number(paramsSize) || 10,
    search: paramsSearch || "",
    startDate: paramsStartDate
      ? formatGetPeriodParams(paramsStartDate)
      : moment().subtract(1, "months"),
    endDate: paramsEndDate ? formatGetPeriodParams(paramsEndDate) : moment(),
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);
  const [loadingButton, setLoadingButton] = useState({
    pdf: false,
    xlsx: false,
  });
  const { startDate, endDate, ...otherParams } = queryParams;

  const handleChangeQueryParams = (value, key) =>
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  const handleChangePageParams = (value, key) => {
    urlParams.set(key, value);
    history.push({ search: urlParams.toString() });
  };
  const handleChangePage = page => {
    handleChangeQueryParams(page, "page");
    handleChangePageParams(page, "page");
  };
  const handleChangePageSize = size => {
    handleChangeQueryParams(size, "size");
    handleChangePageParams(size, "size");
  };
  const handleChangeSearch = event => {
    const { value } = event?.target;
    handleChangeQueryParams(value, "search");
    handleChangePageParams(value, "search");
  };
  const handleChangeStartDate = date => {
    handleChangeQueryParams(date, "startDate");
    handleChangePageParams(formatSetPeriodParams(date), "startDate");
  };
  const handleChangeEndDate = date => {
    handleChangeQueryParams(date, "endDate");
    handleChangePageParams(formatSetPeriodParams(date), "endDate");
  };
  const handleView = item => {
    history.push({
      pathname: "/admin/reports/" + item,
      state: {
        date: formatSetPeriodParams(startDate),
      },
    });
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/publisher/report`, {
        headers,
        params: {
          ...otherParams,
          start_month: formatParamsMonth(startDate),
          end_month: formatParamsMonth(endDate),
          year: formatParamsYear(startDate),
        },
      });
      const { data, meta } = res?.data;
      const modifiedData = (data || [])?.map(item => ({
        ...item,
        usage_on_dsp: (item?.usage_on_dsp || [])?.map(dsp => ({
          ...dsp,
          product_type: dsp?.summaries[0]?.product_type || "",
        })),
      }));
      setDataTable(modifiedData);
      const pageCount = getTotalPage(meta?.total, queryParams?.size);
      setTableTotalPage(pageCount || 1);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  const handleDownloadXlsx = async () => {
    try {
      setLoadingButton({ ...loadingButton, xlsx: true });
      const { data } = await axios(`${hardBaseUrl}/publisher/report/download`, {
        headers,
        params: {
          ...otherParams,
          start_month: formatParamsMonth(startDate),
          end_month: formatParamsMonth(endDate),
          year: formatParamsYear(startDate),
        },
      });
      const link = document.createElement("a");
      link.href = data.data;
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingButton({ ...loadingButton, xlsx: false });
    }
  };
  const handleDownloadPdf = () => {
    setLoadingButton({ ...loadingButton, pdf: true });
    html2canvas(previewReportRef?.current)
      .then(canvas => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          orientation: "p",
          unit: "px",
          format: "a4",
        });
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const widthRatio = pageWidth / canvas.width;
        const heightRatio = pageHeight / canvas.height;
        const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
        const canvasWidth = canvas.width * ratio;
        const canvasHeight = canvas.height * ratio;
        const marginX = (pageWidth - canvasWidth) / 2;
        const marginY = (pageHeight - canvasHeight) / 2;
        pdf.addImage(
          imgData,
          "PNG",
          marginX,
          marginY,
          canvasWidth,
          canvasHeight
        );
        pdf.save("download.pdf");
      })
      .catch(error => {
        ModalError(error);
      })
      .finally(() => {
        setLoadingButton({ ...loadingButton, pdf: false });
      });
  };

  useEffect(() => {
    debounceDataTable();
    if (queryParams?.page !== paramsPage) {
      handleChangePageParams(queryParams?.page, "page");
    }
    return () => {
      debounceDataTable.cancel();
    };
  }, [queryParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="Report">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <div ref={previewReportRef} id={"previewReport"}>
            <HeaderTitle title="Report" breadcrumbData={breadcrumbData} />
            <Divider className={classes.divider} />
            <Grid container justifyContent="space-between" my={4}>
              <Grid item>
                <SearchTextInput
                  placeholder="Search"
                  value={queryParams?.search}
                  onChange={handleChangeSearch}
                />
              </Grid>
              <Grid item>
                <Grid
                  container
                  columnSpacing={1}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid item>
                    <MonthYearRangePicker
                      label="Period"
                      startDate={startDate}
                      handleChangeStartDate={handleChangeStartDate}
                      endDate={endDate}
                      handleChangeEndDate={handleChangeEndDate}
                      syncYear
                      width={250}
                    />
                  </Grid>
                  <Grid item>
                    <PrimaryButton
                      height={40}
                      label="Download PDF"
                      onClick={handleDownloadPdf}
                      disabled={loadingButton?.pdf}
                      loading={loadingButton?.pdf}
                    />
                  </Grid>
                  <Grid item>
                    <PrimaryButton
                      height={40}
                      label="Download XLSX"
                      onClick={handleDownloadXlsx}
                      disabled={loadingButton?.xlsx}
                      loading={loadingButton?.xlsx}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <InnoTableV2
              isLoading={false}
              columns={columnTable}
              items={dataTable}
              page={queryParams?.page}
              rowsPerPage={queryParams?.size}
              totalPage={tableTotalPage}
              handleChangePage={(_, page) => handleChangePage(page)}
              handleChangeRowsPerPage={e =>
                handleChangePageSize(e?.target?.value)
              }
              isHaveAction
              renderAction={item => (
                <PrimaryButton
                  label="See Details"
                  onClick={() => handleView(item.composer_id)}
                  size="small"
                />
              )}
            />
          </div>
        </Container>
      )}
    </Page>
  );
}

const formatParamsMonth = date => date.format("M");
const formatParamsYear = date => date.format("YYYY");
const formatGetPeriodParams = date => moment(date, "M-YYYY");
const formatSetPeriodParams = date => moment(date).format("M-YYYY");
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
}));
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Report",
    active: true,
  },
];
const columnTable = [
  {
    name: "composer_name",
    title: "Composer/Author",
  },
  {
    name: "composer_alias_name",
    title: "Alias Name",
  },
  {
    name: "usage_on_dsp",
    title: "DSP",
    renderText: item => (
      <Box minWidth={100}>
        <TableCellMultipleRow list={item} itemKey="dsp_name" />
      </Box>
    ),
  },
  {
    name: "usage_on_dsp",
    title: "Traffic",
    renderText: item => (
      <TableCellMultipleRow list={item} itemKey="traffic" type="number" />
    ),
  },
  {
    name: "usage_on_dsp",
    title: "Income",
    renderText: item => (
      <TableCellMultipleRow
        list={item}
        itemKey="revenue"
        type="number"
        prefix="Rp"
      />
    ),
  },
  {
    name: "usage_on_dsp",
    title: "Market Share (Traffic)",
    renderText: item => (
      <TableCellMultipleRow list={item} itemKey="traffic_percentage" />
    ),
  },
  {
    name: "usage_on_dsp",
    title: "Market Share (Revenue)",
    renderText: item => (
      <TableCellMultipleRow list={item} itemKey="revenue_percentage" />
    ),
  },
  {
    name: "usage_on_dsp",
    title: "Product Type",
    renderText: item => (
      <TableCellMultipleRow list={item} itemKey="product_type" />
    ),
  },
];
export default Report;
