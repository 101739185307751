import {
  Container,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Box } from "@mui/material";
import {
  ArrayChip,
  ArrayTagChip,
  CurrencyDisplay,
  Page,
  PrimaryButton,
  SecondaryButton,
  SongUsageTrendChart,
  SongUsageTrendSummarytCard,
} from "components";
import { HeaderTitle } from "layouts";
import { useRef, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

const PrintReportReview = () => {
  const classes = useStyles();
  const history = useHistory();
  const { state } = useLocation();
  const inputRef = useRef(null);
  const [loadingButton, setLoadingButton] = useState(false);
  const { dataTable, chartData, chartReport, selectedDSP } = state;

  const handlePrintPDF = useReactToPrint({
    content: () => inputRef.current,
    onBeforeGetContent: () => setLoadingButton(true),
    onAfterPrint: () => setLoadingButton(false),
  });

  return (
    <Page className={classes.root} title="Review of Song Usage">
      <div ref={inputRef}>
        <Container id="content-to-print">
          <HeaderTitle title="Review of Song Usage" />
          <Divider className={classes.divider} />
          <div className={classes?.chartCard}>
            <Box mb={2}>
              {selectedDSP?.length > 0 && (
                <ArrayTagChip
                  selectionsList={
                    selectedDSP.some(item => item.dsp_id === "all")
                      ? [{ dsp_id: "all", name: "All" }]
                      : selectedDSP
                  }
                  value="name"
                />
              )}
            </Box>
            <SongUsageTrendChart chartData={chartData} preview />
          </div>
          <SongUsageTrendSummarytCard chartReport={chartReport} />
          <Table size="small" className={classes.table}>
            <TableHead>
              <TableRow>
                {columnTable?.map((item, index) => (
                  <TableCell key={index} className={classes?.headText}>
                    {item?.title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable && dataTable.length > 0 ? (
                dataTable.map((rowData, index) => (
                  <TableRow hover key={index}>
                    {columnTable?.map((item, index) => (
                      <TableCell key={index}>
                        {item?.type === "number" ||
                        item?.type === "currency" ? (
                          <CurrencyDisplay
                            value={rowData?.[item?.name]}
                            decimalScale={item?.type === "currency" && 2}
                            prefix={item?.type === "currency" && "Rp "}
                          />
                        ) : item?.type === "arrayChip" ? (
                          <ArrayChip list={rowData?.[item?.name]?.split(",")} />
                        ) : (
                          rowData?.[item?.name] || "-"
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow key="0">
                  <TableCell colSpan={12}>
                    <Box p={2}>
                      <Typography align="center">
                        <em>Empty Data</em>
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Container>
      </div>
      <Box justifyContent="flex-end" display="flex" gap={2} m={3}>
        <SecondaryButton label="Back" onClick={() => history.goBack()} />
        <PrimaryButton
          label={loadingButton ? "Please Wait ..." : "Print Report"}
          loading={loadingButton}
          disabled={loadingButton}
          onClick={handlePrintPDF}
        />
      </Box>
    </Page>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  chartCard: {
    border: "1px solid #ebebeb",
    borderRadius: "5px",
    padding: "50px 20px",
  },
  table: {
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
}));
const columnTable = [
  {
    name: "SongTitle",
    title: "Song Title",
  },
  {
    name: "Composer",
    title: "Composer/Author",
    type: "arrayChip",
  },
  {
    name: "Traffic",
    title: "Traffic",
    type: "number",
  },
  {
    name: "OriginalCurrencyRevenue",
    title: "Revenue",
    type: "currency",
  },
  {
    name: "BaseCurrencyComposerRevenue",
    title: "Composer Revenue",
    type: "currency",
  },
  {
    name: "BaseCurrencyPublisherRevenue",
    title: "Publisher Revenue",
    type: "currency",
  },
  {
    name: "ProductType",
    title: "Product Type",
  },
];

export default PrintReportReview;
