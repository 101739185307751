import { NumberInput } from "components";
import { Box, Chip, Grid, styled, Tooltip } from "@mui/material";

const ContractTableMultipleRowInputCell = ({
  list,
  type,
  itemKey,
  handleDeleteComposer,
  handleChangeShareValue,
}) => {
  const noComposer =
    list === null || list === undefined || list === "" || list?.length === 0;
  return (
    <Grid container direction="column">
      {!noComposer
        ? list?.map((songComposer, index) => (
            <Grid item key={index}>
              {list?.length > 1 && index > 0 && <CellBorder />}
              <Tooltip
                title={songComposer?.composer?.sure_name}
                placement="bottom"
              >
                <Box height="40px" alignContent="center">
                  {type === "input" ? (
                    <NumberInput
                      value={songComposer?.[itemKey]}
                      endAdornment="%"
                      onChange={e =>
                        handleChangeShareValue(
                          e?.target?.value,
                          songComposer,
                          itemKey
                        )
                      }
                    />
                  ) : (
                    <Chip
                      label={songComposer?.composer?.sure_name}
                      size="small"
                      onDelete={
                        list?.length > 1
                          ? () =>
                              handleDeleteComposer(
                                songComposer?.composer_id,
                                songComposer?.song_id
                              )
                          : null
                      }
                    />
                  )}
                </Box>
              </Tooltip>
            </Grid>
          ))
        : "-"}
    </Grid>
  );
};

const CellBorder = styled(Box)({
  margin: "8px -16px",
  height: "1px",
  backgroundColor: "#e0e0e0",
});
export default ContractTableMultipleRowInputCell;
