import {
  AppBar,
  Dialog,
  IconButton,
  makeStyles,
  Toolbar,
} from "@material-ui/core";
import { Close, VisibilityOutlined } from "@material-ui/icons";
import { Box, Grid, Typography } from "@mui/material";
import axios from "axios";
import {
  ImportDocumentModal,
  ModalError,
  ModalSuccess,
  ModalWarning,
  PrimaryButton,
  PrimaryIconButton,
  SearchTextInput,
  SelectInput,
  SkeletonComponent,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { getErrors } from "utils";
import TrashVector from "../../../../assets/img/trashVector.svg";
import { hardBaseUrl } from "../../../../services/urlConstant";
const useStyles = makeStyles({
  appBar: {
    position: "relative",
  },
  file: {
    width: "100%",
    height: "100%",
  },
});
const SupportingDocuments = idKontrak => {
  const classes = useStyles();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [queryParams, setQueryParams] = useState({
    type: "-created_at",
    search: "",
  });
  const [dataTable, setDataTable] = useState([]);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [modalDocument, setModalDocument] = useState(false);
  const [openDialogDetail, setOpenDialogDetail] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleCloseModalDocument = () => setModalDocument(false);
  const handleCloseDialogDetail = () => setOpenDialogDetail(false);
  const handleOpenDialogDetail = (url, name) => {
    setFileUrl(url);
    setFileName(name);
    setOpenDialogDetail(true);
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/publisher/contract-document/${idKontrak.idKontrak}`,
        { headers, params: queryParams }
      );
      setDataTable(res?.data?.data || []);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  const handleUploadDocument = async documentFiles => {
    const formData = new FormData();
    formData.append("documents", documentFiles[0]);
    if (idKontrak.contractType === "song") {
      formData.append("contract_id", idKontrak.idKontrak);
    }
    try {
      setLoadingButton(true);
      await axios.post(
        `${hardBaseUrl}/contract-document${
          idKontrak.contractType === "main"
            ? `/contract/composer-main?contract_id=${idKontrak.idKontrak}`
            : ""
        }`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      ModalSuccess("Document has been uploaded").then(() => getDataTable());
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingButton(false);
      handleCloseModalDocument();
    }
  };
  const deleteDocument = async id => {
    try {
      const payload = {
        ids: [id],
      };
      await axios.delete(
        `${hardBaseUrl}/contract-document${
          idKontrak.contractType === "song" ? "" : "/contract/composer-main"
        }`,
        { data: payload, headers }
      );
      ModalSuccess("Document has been deleted").then(() => getDataTable());
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };

  const CustomAction = item => {
    return (
      <Grid container columnSpacing={1} width={120}>
        <Grid item>
          <PrimaryIconButton
            tooltipTitle="View"
            onClick={() => handleOpenDialogDetail(item?.url, item?.filename)}
            icon={<VisibilityOutlined />}
          />
        </Grid>
        <Grid item>
          <PrimaryIconButton
            tooltipTitle="Delete"
            onClick={() =>
              ModalWarning(
                "Are you sure you want to delete this document?",
                "Delete Document"
              ).then(
                res =>
                  res?.isConfirmed && deleteDocument(item?.contract_document_id)
              )
            }
            icon={<img alt="delete" src={TrashVector} />}
          />
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    const isSearching = Boolean(queryParams?.search);
    if (isSearching) {
      debounceDataTable();
      return () => {
        debounceDataTable.cancel();
      };
    } else {
      getDataTable();
    }
  }, [queryParams, debounceDataTable]);

  return loadingPage ? (
    <SkeletonComponent variant="wave" />
  ) : (
    <div>
      <Grid container justifyContent="space-between" mb="16px">
        <Grid item alignItems="center">
          <SearchTextInput
            value={queryParams?.search}
            onChange={e => handleChangeQueryParams(e?.target?.value, "search")}
            placeholder="Search"
          />
        </Grid>
        <Grid item>
          <Grid container columnSpacing={1} alignItems="center">
            <Grid item>
              <SelectInput
                options={optionSort}
                optionKey="key"
                optionLabel="value"
                value={queryParams?.type}
                onChange={e =>
                  handleChangeQueryParams(e?.target?.value, "type")
                }
                width={200}
              />
            </Grid>
            <Grid item>
              <PrimaryButton
                label="Import Document"
                onClick={() => setModalDocument(true)}
                size="large"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <InnoTableV2
        isLoading={false}
        columns={columnTable}
        items={dataTable}
        isHaveAction
        renderAction={CustomAction}
      />
      <Dialog
        fullScreen
        open={openDialogDetail}
        onClose={handleCloseDialogDetail}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseDialogDetail}
            >
              <Close />
            </IconButton>
            <Typography textAlign="center">{fileName}</Typography>
          </Toolbar>
        </AppBar>
        <Box width="100%" height="100%">
          <iframe src={fileUrl} className={classes.file} />
        </Box>
      </Dialog>

      {modalDocument && (
        <ImportDocumentModal
          open={modalDocument}
          onClose={handleCloseModalDocument}
          onSubmit={handleUploadDocument}
          fileType=".pdf"
          loadingButton={loadingButton}
          multiple
        />
      )}
    </div>
  );
};
const optionSort = [
  {
    key: "-created_at",
    value: "Latest",
  },
  {
    key: "created_at",
    value: "Oldest",
  },
];
const columnTable = [
  {
    name: "filename",
    title: "Document Title",
    renderText: item => item || "-",
  },
  {
    name: "first_name",
    title: "Uploaded At",
  },
];
export default SupportingDocuments;
