export const topMenuButtonCaris = [
  {
    id: 0,
    title: "Personalization",
    link: "/admin/konfigurasi/personalisasi",
  },
  {
    id: 1,
    title: "Payment",
    link: "/admin/konfigurasi/pembayaran",
  },
  {
    id: 2,
    title: "Original Publisher",
    link: "/admin/konfigurasi/original-publisher",
  },
  {
    id: 3,
    title: "Activity",
    link: "/admin/konfigurasi/aktifitas",
  },
  {
    id: 4,
    title: "Invoice",
    link: "/admin/konfigurasi/invoice",
  },
  {
    id: 5,
    title: "Reminder",
    link: "/admin/konfigurasi/contract-reminder",
  },
  {
    id: 6,
    title: "Tax",
    link: "/admin/konfigurasi/tax",
  },
  {
    id: 7,
    title: "Exchanges Rate",
    link: "/admin/konfigurasi/exchange-rate",
  },
];

export const topMenuButtonMPIS = role => [
  ...(role === "society" || role === "association"
    ? [
        {
          id: 0,
          title: "Activity",
          link: "/admin/konfigurasi/aktifitas",
        },
      ]
    : [
        ...(role === "admin"
          ? [
              {
                id: 0,
                title: "System",
                link: "/admin/konfigurasi/sistem",
              },
              {
                id: 1,
                title: "Notification Template",
                link: "/admin/konfigurasi/template-notifikasi",
              },
              {
                id: 2,
                title: "Tax",
                link: "/admin/konfigurasi/pajak",
              },
              {
                id: 3,
                title: "Role",
                link: "/admin/konfigurasi/role-user",
              },
              {
                id: 4,
                title: "Activity",
                link: "/admin/konfigurasi/aktifitas-admin",
              },
              {
                id: 5,
                title: "Billing",
                link: "/admin/konfigurasi/billing-configuration",
              },
              {
                id: 6,
                title: "DSP Type Revenue",
                link: "/admin/konfigurasi/dsp-type-revenue",
              },
              {
                id: 7,
                title: "Announcement Management",
                link: "/admin/konfigurasi/announcement-management",
              },
            ]
          : [
              {
                id: 0,
                title: "Personalization",
                link: "/admin/konfigurasi/personalisasi",
              },
              {
                id: 1,
                title: "Payment",
                link: "/admin/konfigurasi/pembayaran",
              },
              {
                id: 2,
                title: "Original Publisher",
                link: "/admin/konfigurasi/original-publisher",
              },
              {
                id: 3,
                title: "Activity",
                link: "/admin/konfigurasi/aktifitas",
              },
              {
                id: 4,
                title: "Sub Reporting",
                link: "/admin/konfigurasi/sub-reporting",
              },
              {
                id: 5,
                title: "Sub Reporting Approval",
                link: "/admin/konfigurasi/sub-reporting-approval",
              },
            ]),
        {
          id: role === "admin" ? 8 : 6,
          title: "Exchanges Rate",
          link: "/admin/konfigurasi/exchange-rate",
        },
      ]),
];
