import axios from "axios";
import { ModalError, ModalSuccess } from "components";
import { useState } from "react";
import { getErrors } from "utils";
import { hardBaseUrl } from "../../../services/urlConstant";
import ContractForm from "./Components/ContractForm";

function AddContract() {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: "Bearer " + token,
  };
  const urlContract = `${hardBaseUrl}/publisher/contract`;
  const urlDocuments = `${hardBaseUrl}/contract-document`;
  const formData = new FormData();
  const [loadingContract, setLoadingContract] = useState(false);

  const handlSubmitData = async (
    payload,
    supportingDocumentFiles,
    contractType
  ) => {
    const isSongContract = contractType === "Song Contract";
    const mainContractFormEndpoint = !isSongContract ? "/composer-main" : "";
    const mainContractDocumentsEndpoint = !isSongContract
      ? "/contract/composer-main"
      : "";
    try {
      setLoadingContract(true);
      const res = await axios.post(
        `${urlContract}${mainContractFormEndpoint}`,
        payload,
        { headers }
      );
      if (res?.status === 200 && supportingDocumentFiles) {
        const contractId = res?.data?.data?.contract_id;
        formData.append("contract_id", contractId);
        formData.append("documents", supportingDocumentFiles[0]);
        try {
          await axios.post(
            `${urlDocuments}${mainContractDocumentsEndpoint}`,
            formData,
            { headers }
          );
          ModalSuccess().then(() => history.goBack());
        } catch (error) {
          ModalError(getErrors(error?.response));
        }
      }
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingContract(false);
    }
  };
  return (
    <ContractForm
      title="Add Contract"
      handlSubmitData={handlSubmitData}
      loadingContract={loadingContract}
    />
  );
}

export default AddContract;
