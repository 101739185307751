import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputAdornment,
  OutlinedInput,
  Paper,
  Snackbar,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import { BackupOutlined } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import { DatePicker, Page } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import gradients from "../../../../theme/gradients";

import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  card: {
    width: theme.breakpoints.values.md,
    maxWidth: "100%",
    overflow: "visible",
    display: "flex",
    position: "relative",
    "& > *": {
      flexGrow: 1,
      flexBasis: "50%",
      width: "50%",
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  avatar_large: {
    width: theme.spacing(16),
    height: theme.spacing(16),
  },
  icon: {
    backgroundImage: gradients.green,
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: "absolute",
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
    fontSize: 32,
  },
  loginForm: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  person: {
    marginTop: theme.spacing(2),
    display: "flex",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  title: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },
  tableSong: {
    maxHeight: 200
  },
  tambahLagu: {
    backgroundColor: "black",
    color: "white",
    width: "32px",
    height: "29px",
    borderRadius: "6px",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  textTambahLagu: {
    marginLeft: "10px",
    marginTop: "5px",
    fontSize: "14px",
    color: "#687083",
  },
  btnDialog: {
    backgroundColor: "black",
    color: "white",
    padding: "10px 28px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  btnSubmit: {
    backgroundColor: "black",
    color: "white",
    padding: "10px 28px",
    height: "40px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  iconClose: {
    color: "#364052",
    width: "12px",
    height: "12px",
    marginTop: "3px",
    cursor: "pointer",
  },
  textNoData: {
    fontWeight: 400,
    color: "#687083",
    fontSize: "14px",
  },
  cardComposer: {
    border: "1px solid #9AA2B1",
    borderRadius: "6px",
    boxShadow: "none",
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  bodyTextBlack: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#111827",
  },
  bodyTextGrey: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#687083",
  },
  cardFiles: {
    backgroundColor: "white",
    boxShadow: "none",
    borderRadius: "6px",
    border: "1px solid #E5E5E5",
  },
  tableRow: {
    height: 20,
  },
  conTextBlack: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  cardDate: {
    border: "1px solid #9AA2B1",
    borderRadius: "6px",
    boxShadow: "none",
  },
  textP: {
    marginTop: "5px",
    color: "#8f8f8f",
    fontSize: "14px",
  },
  textAddContract: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
  },
  paperModal: {
    paddingTop: "10px",
    paddingBottom: "10px",
    backgroundColor: "#F9FAFB",
    cursor: "pointer",
  },
  insertIcon: {
    color: "#9AA2B1",
    width: "13.33px",
    height: "15px",
    marginTop: "3px",
  },
  textFiles: {
    fontSize: "14px",
    fontWeight: 400,
    marginLeft: "5px",
  },
  textDefault: {
    textTransform: "none",
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    textDecoration: "none",
  },
  dropdown: {
    color: "black",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  textAdd: {
    textTransform: "none",
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
  },
  searchSong: {
    width: "100%"
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  marginBottom: {
    marginBottom: "20px",
  },
  btnPerpetual: {
    backgroundColor: "black",
    marginRight: "10px",
    borderColor: "black",
    border: "1px solid grey",
  },
  btnYear: {
    backgroundColor: "#FFFFFF",
    borderColor: "grey",
    border: "1px solid #9AA2B1",
    color: "black",
    "&:hover": {
      backgroundColor: "white",
      color: "black",
    },
  },
  typographyPerpetual: {
    textTransform: "none",
    color: "white",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    textDecoration: "none",
  },
  typographyYear: {
    textTransform: "none",
    color: "black",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    textDecoration: "none",
  },
  typography: ({ timePeriod, selectedPeriod }) => ({
    textTransform: "none",
    color: timePeriod === selectedPeriod ? "#FFFFFF" : "black",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    textDecoration: "none",
  }),
  cardUpload: {
    border: "1px solid #9AA2B1",
    borderRadius: "6px",
    boxShadow: "none",
  },
  marginTop20: {
    marginTop: "20px",
  },
  backupIcon: {
    color: "grey",
    fontSize: "40px",
  },
  dropzone: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "2px dashed #9AA2B1",
    padding: theme.spacing(2),
    borderRadius: "6px",
    cursor: "pointer"
  },
}));


function TambahKontrak() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [songList, setSongList] = useState([]);

  const checked = false;
  const [penciptaList, setPenciptaList] = useState([]);

  const [songId, setSongId] = useState(null);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [dateRange, setDateRange] = useState(0);
  const [gambar, setGambar] = useState([]);
  const [keterangan, setKeterangan] = useState("");
  const [openSnack, setOpenSnack] = useState(false);

  const [typeSnackbar, setTypeSnackbar] = useState("success");
  const [message, setMessage] = useState("This is a message!");
  const [wordsSong, setWordsSong] = useState("");
  const [getColor, setGetColor] = useState("");
  const [timePeriod, setTimePeriod] = useState("1");

  const [isLoadSong, setIsLoadSong] = useState(true);

  const handleDrop = useCallback(acceptedFiles => {
    if (acceptedFiles[0].size > 2000000) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "File size cannot be more than 2mb",
      });
    } else {
      setGambar(acceptedFiles);
    }
  }, []);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    accept: ".pdf",
    onDrop: handleDrop,
  });

  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };
  const handleRemoveFile = idx => {
    setGambar(gambar.filter((_, index) => index !== idx));
    acceptedFiles.splice(idx, 1);
  };
  const files =
    acceptedFiles[0]?.size > 2000000
      ? null
      : acceptedFiles.map((file, i) => {
        return (
          <Card
            key={file.name}
            className={classes.cardFiles}
          >
            <CardContent>
              <Box display="flex" justifyContent="space-between">
                <Box display="flex">
                  <InsertDriveFileOutlinedIcon className={classes.insertIcon} />
                  <Typography className={classes.textFiles}>
                    {truncate(file.name, 20)}
                  </Typography>
                </Box>
                <CloseOutlinedIcon
                  onClick={() => handleRemoveFile(i)}
                  className={classes.iconClose}
                />
              </Box>
            </CardContent>
          </Card>
        );
      });

  const openModalLagu = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const tableRowClick = id => {
    songList.map(item => {
      if (item.song_id === id) {
        item.checked = !item.checked;
        const newData = item.song_composer.map(item2 => ({
          ...item2,
          title_song: item.title_song,
        }));
        setPenciptaList(newData);
        setSongId(item.song_id);
      } else {
        item.checked = false;
      }
    });
  };

  const getResponse = async () => {
    try {
      const token = localStorage.getItem("token");
      const url =
        hardBaseUrl + "/publisher/song?page=1&size=10&search=" + wordsSong;

      const options = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      const res = await axios.get(url, options);

      const newData = res.data.data.map(item => ({
        ...item,
        checked: false,
      }));
      setSongList(newData);

      setIsLoadSong(false);
    } catch (error) {
      new Error(error);
      setIsLoadSong(false);
    }
  };

  useEffect(() => {
    getResponse();
    const getMainDsp = async () => {
      try {
        const token = localStorage.getItem("token");
        const url = `${hardBaseUrl}/me`;
        const res = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setGetColor(res.data.data.publisher.theme_color);
      } catch (e) {
        new Error(e);
      }
    };
    getMainDsp();
  }, [checked, startDate, endDate, wordsSong]);

  const listPencipta = data => {
    let pencipta = data;

    if (pencipta === null || undefined || "") {
      return <div>-</div>;
    } else if (pencipta.length <= 3) {
      return pencipta.map((pencipta, index) => (
        <div key={index}>
          <ThemeProvider theme={theme}>
            <Chip
              key={index}
              label={pencipta.composer.sure_name}
              className={classes.tableChip}
              size="small"
            />
          </ThemeProvider>
        </div>
      ));
    } else if (pencipta.length > 3) {
      let pencipta1 = pencipta.slice(0, 3);
      let pencipta2 = pencipta.slice(3, pencipta.length);
      return (
        <div>
          <ThemeProvider theme={theme}>
            {pencipta1.map((pencipta, index) => (
              <Chip
                key={index}
                label={pencipta.composer.sure_name}
                className={classes.tableChip}
                size="small"
              />
            ))}
            <Chip
              label={"+" + pencipta2.length}
              className={classes.tableChip}
              size="small"
            />
          </ThemeProvider>
        </div>
      );
    }
  };

  const handleDateChangeStartDate = date => {
    setStartDate(date);
  };

  const handleDateChangeEndDate = date => {
    if (date < startDate) {
      setStartDate(date);
      setMessage("End date must be greater than start date");
      setTypeSnackbar("error");
      setOpenSnack(true);
    } else {
      setEndDate(date);
    }
  };

  const handleDateRangeChange = event => {
    setEndDate(
      new Date(startDate.getTime() + event.target.value * 24 * 60 * 60 * 1000)
    );

    setDateRange(event.target.value);
  };

  const handleDateRangeChangeYear = event => {
    setEndDate(
      new Date(
        startDate.getTime() + event.target.value * 365 * 24 * 60 * 60 * 1000
      )
    );
    setDateRange(event.target.value);
  };

  const getDateFormatForFilter = date => {
    if (!date) return "";
    return moment(date).format("YYYY-MM-DD");
  };

  const handleButtonSimpan = async e => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("token");

      if (songId === null) {
        setTypeSnackbar("error");
        setMessage("lagu Tidak Boleh Kosong.");
        setOpenSnack(true);
      }
      if (startDate === "" || null || undefined) {
        setTypeSnackbar("error");
        setMessage("Tanggal Mulai Tidak Boleh Kosong.");
        setOpenSnack(true);
      }
      if (endDate === "" || null || undefined) {
        setTypeSnackbar("error");
        setMessage("Tanggal Selesai Tidak Boleh Kosong.");
        setOpenSnack(true);
      }

      if (songId !== null && startDate !== null && endDate !== null) {
        const formData = new FormData();
        formData.append("song_ids", songId);
        formData.append("date_periode", Number(dateRange));
        formData.append("start_date", getDateFormatForFilter(startDate));
        formData.append("end_date", getDateFormatForFilter(endDate));
        formData.append("description", keterangan);
        formData.append("documents", gambar[0]);

        let baseOfUrl = hardBaseUrl;
        let urlUse = baseOfUrl + "/publisher/contract/original-publisher";

        let resultAdd = await axios.post(urlUse, formData, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });

        if (
          resultAdd.data.message === "success" &&
          resultAdd.data.meta.http_status === 200
        ) {
          Swal.fire({
            title: "Success",
            text: "Data Berhasil Disimpan",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          }).then(result => {
            if (result.isConfirmed) {
              window.location.href = "/admin/kontrak-original-publisher";
            }
          });
        } else {
          setTypeSnackbar("error");
          setMessage("Tambah Kontrak Gagal.");
          setOpenSnack(true);
        }
      }
    } catch (err) {
      Swal.fire({
        title: "Error",
        text: "Error.",
        icon: "error",
      }).then(() => { });
    }
  };

  const handleChangeText = e => {
    setKeterangan(e.target.value);
  };
  const handleCloseSnackbar = () => {
    setOpenSnack(false);
  };

  const handleTimePeriodYear = () => {
    setTimePeriod("2");
  };

  const handleTimePeriodUnlimited = () => {
    setTimePeriod("1");
  };
  const changeSearchSong = e => {
    setIsLoadSong(true);
    setWordsSong(e);
  };
  return (
    <Page className={classes.root} title="Add Contract Original Publisher">
      <Container maxWidth={false}>
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleCloseSnackbar} severity={typeSnackbar}>
            {message}
          </Alert>
        </Snackbar>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography className={classes.textAddContract}>
                Add Contract
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>Contract</Typography>
              <Typography className={classes.breadCrumbsActive}>
                Add Contract
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container>
          <Box>
            <ThemeProvider theme={theme}>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                Song
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <p className={classes.textP}>
                This feature can add multiple songs in one contract
              </p>
            </ThemeProvider>
          </Box>
        </Grid>

        <Grid container>
          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
            <Grid container>
              <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                <Box margin="15px 0px">
                  <Paper
                    className={classes.paperModal}
                    onClick={openModalLagu}
                  >
                    {
                      <Container>
                        <Box display="flex">
                          <IconButton
                            onClick={openModalLagu}
                            className={classes.tambahLagu}
                          >
                            <AddIcon />
                          </IconButton>
                          <ThemeProvider theme={theme}>
                            <Typography
                              variant="subtitle2"
                              className={classes.textTambahLagu}
                            >
                              Click to add song
                            </Typography>
                          </ThemeProvider>
                        </Box>
                      </Container>
                    }
                  </Paper>
                  <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="max-width-dialog-title"
                  >
                    <DialogTitle id="max-width-dialog-title">
                      Search Song
                    </DialogTitle>
                    <Grid container>
                      <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                        <Container>
                          <OutlinedInput
                            className={classes.searchSong}
                            id="outlined-adornment-password"
                            placeholder="Search"
                            value={wordsSong}
                            onChange={e => {
                              changeSearchSong(e.target.value);
                            }}
                          />

                          <Grid container>
                            <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                              <Divider className={classes.divider} />
                            </Grid>
                          </Grid>

                          <TableContainer className={classes.tableSong}>
                            <Table stickyHeader size="small">
                              <TableHead>
                                <TableRow>
                                  <ThemeProvider theme={theme}>
                                    <TableCell>
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.headText}
                                      >
                                        ISWC Code
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.headText}
                                      >
                                        Song Title
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.headText}
                                      >
                                        Composer/Author Name
                                      </Typography>
                                    </TableCell>
                                  </ThemeProvider>
                                  <TableCell></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {isLoadSong ? (
                                  <>
                                    <TableRow>
                                      <TableCell align="center" colSpan={3}>
                                        <CircularProgress />
                                      </TableCell>
                                    </TableRow>
                                  </>
                                ) : (
                                  <>
                                    {songList.map(customer => (
                                      <TableRow
                                        hover
                                        key={customer.song_id}
                                        onClick={() => {
                                          tableRowClick(
                                            customer.song_id,
                                            customer.song_composer
                                          );
                                        }}
                                      >
                                        <ThemeProvider theme={theme}>
                                          <TableCell>
                                            <Typography
                                              variant="subtitle2"
                                              className={classes.bodyTextGrey}
                                            >
                                              {customer.isrc_code}
                                            </Typography>
                                          </TableCell>
                                          <TableCell>
                                            <Typography
                                              variant="subtitle2"
                                              className={
                                                classes.bodyTextBlack
                                              }
                                            >
                                              {customer.title_song}
                                            </Typography>
                                          </TableCell>

                                          <TableCell>
                                            <Typography
                                              variant="subtitle2"
                                              className={
                                                classes.bodyTextBlack
                                              }
                                            >
                                              {listPencipta(
                                                customer.song_composer
                                              )}
                                            </Typography>
                                          </TableCell>
                                        </ThemeProvider>
                                        <TableCell>
                                          {customer.checked === true ? (
                                            <CheckIcon />
                                          ) : (
                                            ""
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Container>
                      </Grid>
                    </Grid>
                    <DialogContent></DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleClose}
                        className={classes.btnDialog}
                        style={{
                          backgroundColor: getColor || "black",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.textDefault}>
                            Cancel
                          </Typography>
                        </ThemeProvider>
                      </Button>
                      <Button
                        onClick={handleClose}
                        className={classes.btnDialog}
                        variant="contained"
                        style={{
                          backgroundColor: getColor || "black",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.textDefault}>
                            Choose
                          </Typography>
                        </ThemeProvider>
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box>
          <ThemeProvider theme={theme}>
            <Typography
              component="h1"
              variant="h3"
              className={classes.subTitle}
            >
              Composer/Author
            </Typography>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
            <p className={classes.textP}>
              Composer/Author Information
            </p>
          </ThemeProvider>
        </Box>
        <Grid container>
          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
            <Grid container>
              <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                <Card className={classes.cardComposer}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <ThemeProvider theme={theme}>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Song
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Composer/Author Name
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              ISWC Code
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Ownership Percentage
                            </Typography>
                          </TableCell>
                        </ThemeProvider>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {penciptaList !== null ? (
                        penciptaList?.length > 0 ? (
                          penciptaList.map(customer => (
                            <TableRow hover key={customer.composer_id}>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.bodyTextBlack}
                                >
                                  {customer.title_song}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.bodyTextBlack}
                                >
                                  {customer.composer.sure_name}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.bodyTextGrey}
                                >
                                  {customer.composer.iswc_code}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.bodyTextBlack}
                                >
                                  {customer.percentage_ownership}%
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={4} align="center">
                              <ThemeProvider theme={theme}>
                                <Typography
                                  variant="h6"
                                  className={classes.textNoData}
                                >
                                  No Data
                                </Typography>
                              </ThemeProvider>
                            </TableCell>
                          </TableRow>
                        )
                      ) : (
                        <TableRow>
                          <TableCell colSpan={3} align="center">
                            <ThemeProvider theme={theme}>
                              <Typography
                                variant="h6"
                                className={classes.textNoData}
                              >
                                No Data
                              </Typography>
                            </ThemeProvider>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
            <Divider className={classes.divider} />
          </Grid>
        </Grid>

        <Box>
          <ThemeProvider theme={theme}>
            <Typography
              component="h1"
              variant="h3"
              className={classes.subTitle}
            >
              Information Contract
            </Typography>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
            <p className={classes.textP}>
              Form to fill out information data related to the contract to be
              made
            </p>
          </ThemeProvider>
        </Box>
        <Box className={classes.marginBottom}>
          <Button
            onClick={handleTimePeriodUnlimited}
            className={`${classes.btnDialog} ${classes.btnPerpetual}`}
            variant="contained"
          >
            <ThemeProvider theme={theme}>
              <Typography className={classes.typographyPerpetual}>
                Perpetual
              </Typography>
            </ThemeProvider>
          </Button>
          <Button
            onClick={handleTimePeriodYear}
            className={`${classes.btnDialog} ${classes.btnYear}`}
            variant="contained"
          >
            <ThemeProvider theme={theme}>
              <Typography className={classes.typographyYear}>
                Year
              </Typography>
            </ThemeProvider>
          </Button>
        </Box>

        <Grid container>
          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
            <Card className={classes.cardDate}>
              <CardContent>
                <Grid container>
                  <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                    <Box margin="5px 0px">
                      <Box margin="10px 0px">
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            Time Period
                          </Typography>
                        </ThemeProvider>
                      </Box>
                      <Box marginTop="-10px" marginBottom="10px">
                        <TextField
                          defaultValue={0}
                          variant="outlined"
                          onChange={
                            timePeriod === "1"
                              ? handleDateRangeChange
                              : handleDateRangeChangeYear
                          }
                          name="numberformat"
                          id="formatted-numberformat-input"
                          margin="dense"
                          fullWidth={true}
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                            endAdornment: (
                              <InputAdornment position="end">
                                {timePeriod === "1" ? "Day" : "Year"}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={6} sm={6} lg={6} xl={6}></Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                    <Box margin="5px 0px">
                      <Box margin="10px 0px">
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            Start Date
                          </Typography>
                        </ThemeProvider>
                      </Box>
                      <Box marginTop="-10px" marginBottom="10px">
                        <DatePicker
                          onChange={handleDateChangeStartDate}
                          value={startDate}
                        />
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                    <Box margin="5px 0px">
                      <Box margin="10px 0px">
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            End Date
                          </Typography>
                        </ThemeProvider>
                      </Box>
                      <Box marginTop="-10px" marginBottom="10px">
                        <DatePicker
                          value={endDate}
                          onChange={handleDateChangeEndDate}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                    <Box margin="5px 0px">
                      <Box margin="10px 0px">
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            Description
                          </Typography>
                        </ThemeProvider>
                      </Box>
                      <Box marginTop="-10px" marginBottom="10px">
                        <TextField
                          multiline={true}
                          minRows={10}
                          id="outlined-basic"
                          placeholder="Description"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          name="keterangan"
                          onChange={e => handleChangeText(e)}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
        </Grid>
        <Grid container>
          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
            <Divider className={classes.divider} />
          </Grid>
        </Grid>
        <Box>
          <ThemeProvider theme={theme}>
            <Typography
              component="h1"
              variant="h3"
              className={classes.subTitle}
            >
              Supporting Document
            </Typography>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
            <p className={classes.textP}>
              Upload supporting documents, such as scanned agreement documents
              or can be in the form of pdf files
            </p>
          </ThemeProvider>
        </Box>
        <Grid container>
          <Grid item xs={8}>
            <Card className={classes.cardUpload}>
              <CardContent>
                <ThemeProvider theme={theme}>
                  <Typography variant="subtitle2" className={classes.label}>
                    Upload Document
                  </Typography>
                </ThemeProvider>
                <Box className={classes.marginTop20}>
                  <Box {...getRootProps({ className: classes.dropzone })}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <>
                        <BackupOutlined className={classes.backupIcon} />
                        <Typography variant="h6">
                          <p>Drop the files here ...</p>
                        </Typography>
                      </>
                    ) : (
                      <>
                        <BackupOutlined className={classes.backupIcon} />
                        <Typography variant="h6">
                          <p>
                            <b>Search File</b>, Drag document here
                          </p>
                        </Typography>
                      </>
                    )}
                  </Box>
                  <Box className={classes.marginTop20}>
                    <aside>
                      <Typography component="h1" variant="h5">
                        Files :
                      </Typography>
                      <ul>
                        <Typography component="h1" variant="h5">
                          {files}
                        </Typography>
                      </ul>
                    </aside>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            onClick={e => handleButtonSimpan(e)}
            className={classes.btnSubmit}
          >
            <ThemeProvider theme={theme}>
              <Typography className={classes.textAdd}>
                Add Contract
              </Typography>
            </ThemeProvider>
          </Button>
        </Box>
      </Container>
    </Page>
  );
}

export default TambahKontrak;
