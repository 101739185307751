import { Container, Divider } from "@material-ui/core";
import { VisibilityOutlined } from "@material-ui/icons";
import { Grid } from "@mui/material";
import axios from "axios";
import {
  ButtonGroupTop,
  CurrencyDisplay,
  DateTimeDisplay,
  ModalError,
  ModalSuccess,
  ModalWarning,
  Page,
  PrimaryButton,
  PrimaryIconButton,
  SearchTextInput,
  SelectInput,
  SkeletonComponent,
} from "components";
import { InnoTable } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { globalStyles } from "styles";
import { formatDateTime, getCookie, getErrors } from "utils";
import TrashVector from "../../../assets/img/trashVector.svg";
import { hardBaseUrl, hardTypeWeb } from "../../../services/urlConstant";
import { topMenuButtonMPIS } from "constants";
import { topMenuButtonCaris } from "constants";

function ExchangesRate(props) {
  const classes = globalStyles();
  const history = useHistory();
  const { userRole } = props;
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);
  const isMpis = hardTypeWeb === "mpis";
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const urlParams = new URLSearchParams(location.search);
  const paramsSearch = urlParams.get("search");
  const paramsPage = urlParams.get("page");
  const paramsSize = urlParams.get("per_page");
  const paramsCurrency = urlParams.get("currency_id");

  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: Number(paramsPage) || 1,
    per_page: Number(paramsSize) || 10,
    search: paramsSearch || "",
    currency_id: paramsCurrency || "",
    ...(!isMpis && { publisher_id: userLogin?.publisher?.publisher_id }),
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);
  const [optionCurrency, setOptionCurrency] = useState([]);
  const selectedCurrency = selectedId =>
    optionCurrency?.find(option => option.id === selectedId);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
    handleChangePageParams(value, key);
    if (key !== "page") {
      handleChangePageParams(1, "page");
    }
  };
  const handleChangePageParams = (value, key) => {
    urlParams.set(key, value);
    history.push({ search: urlParams.toString() });
  };
  const handleCreate = () => {
    if (queryParams?.currency_id) {
      history.push(
        `/admin/konfigurasi/exchange-rate/add/${queryParams?.currency_id}/${
          selectedCurrency(queryParams?.currency_id)?.currency_iso_code
        }`
      );
    } else {
      ModalError("Please Select Currency");
    }
  };
  const handleView = item =>
    history.push(
      `/admin/konfigurasi/exchange-rate/view/${item?.id}/${
        selectedCurrency(item?.currency_id)?.currency_iso_code
      }`
    );
  const handleDelete = item => {
    const { currency_id, start_date, id } = item;
    ModalWarning(
      `Are you sure you want to end period of this Exchange Rate (${selectedCurrency(
        currency_id
      )?.currency_iso_code || "-"} at ${formatDateTime(start_date)})`,
      "End Period Exchange Rate"
    ).then(res => res?.isConfirmed && deleteCurrency(id));
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/exchange-rate/datatable`, {
        headers,
        params: queryParams,
      });
      const { data, meta } = res?.data;
      setDataTable(data || []);
      const pageCount = getTotalPage(meta?.total, queryParams?.per_page);
      setTableTotalPage(pageCount || 1);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  const getOptionCurrency = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/currency/datatable?per_page=99&page=1`,
        { headers }
      );
      setOptionCurrency(res?.data?.data);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const deleteCurrency = async id => {
    try {
      await axios.delete(`${hardBaseUrl}/exchange-rate/delete/${id}`, {
        headers,
      });
      ModalSuccess("Successfully Delete Exchange Rate").then(() =>
        getDataTable()
      );
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };

  const CustomAction = item => {
    const givenDate = moment(item?.end_date);
    const isAfter = givenDate.isAfter(moment().add(15, "minutes"));
    return (
      <Grid container columnSpacing={1} width={120}>
        <Grid item>
          <PrimaryIconButton
            icon={<VisibilityOutlined />}
            onClick={() => handleView(item)}
            tooltipTitle="Details"
          />
        </Grid>
        {isAfter && (
          <Grid item>
            <PrimaryIconButton
              icon={<img src={TrashVector} alt="update-icon" />}
              onClick={() => handleDelete(item)}
              tooltipTitle="End Period"
            />
          </Grid>
        )}
      </Grid>
    );
  };

  useEffect(() => {
    getOptionCurrency();
  }, []);
  useEffect(() => {
    const isSearching = Boolean(queryParams?.search);
    if (isSearching) {
      debounceDataTable();
      return () => {
        debounceDataTable.cancel();
      };
    } else {
      getDataTable();
    }
  }, [queryParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="Exchanges Rate">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="Configuration" breadcrumbData={breadcrumbData} />
          <ButtonGroupTop
            items={isMpis ? topMenuButtonMPIS(userRole) : topMenuButtonCaris}
          />
          <Divider className={classes.flatDivider} />
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            my="16px"
          >
            <Grid item>
              <SearchTextInput
                placeholder="Search"
                value={queryParams?.search}
                onChange={e =>
                  handleChangeQueryParams(e?.target?.value, "search")
                }
              />
            </Grid>
            <Grid item>
              <Grid container columnSpacing={1} alignItems="center">
                <Grid item>
                  <SelectInput
                    label="Currrency"
                    options={optionCurrency}
                    optionKey="id"
                    optionLabel="currency_iso_code"
                    value={queryParams?.currency_id || ""}
                    onChange={e =>
                      handleChangeQueryParams(e?.target?.value, "currency_id")
                    }
                    placeholder="None"
                    width={250}
                  />
                </Grid>
                <Grid item>
                  <PrimaryButton
                    label="Create Exchanges Rate"
                    onClick={handleCreate}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <InnoTable
            isLoading={false}
            columns={columnTable({ selectedCurrency })}
            items={dataTable}
            page={queryParams?.page}
            rowsPerPage={queryParams?.per_page}
            totalPage={tableTotalPage}
            handleChangePage={(_, page) =>
              handleChangeQueryParams(page, "page")
            }
            handleChangeRowsPerPage={e =>
              handleChangeQueryParams(e?.target?.value, "per_page")
            }
            isHaveAction
            renderAction={CustomAction}
          />
        </Container>
      )}
    </Page>
  );
}

const breadcrumbData = [
  {
    label: "Configuration",
    link: "/admin/konfigurasi/exchange-rate",
  },
  {
    label: "Exchange Rate",
    active: true,
  },
];
const columnTable = ({ selectedCurrency }) => [
  {
    name: "currency_id",
    title: "Currency",
    renderText: item => selectedCurrency(item)?.currency_iso_code || "-",
  },
  {
    name: "start_date",
    title: "Start Date",
    renderText: date => <DateTimeDisplay date={date} />,
  },
  {
    name: "end_date",
    title: "End Date",
    renderText: date => <DateTimeDisplay date={date} />,
  },
  {
    name: "value",
    title: "Exchange Rate",
    renderText: item => (
      <CurrencyDisplay value={item} prefix="Rp " decimalScale={2} />
    ),
  },
];
export default ExchangesRate;
