import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import AuthLayout from "../../layouts/Auth";
import ErrorLayout from "../../layouts/Error";
import DashboardAdminLayout from "../../layouts/DashboardAdmin";
import LoginCaris from "../../pages/LandingPage/LoginCaris/LoginCaris";
import PrivacyPolicy from "../../pages/LandingPage/PrivacyPolicy";

import {
  KonfigurasiAdminAktifitas,
  KonfigurasiAdminPajak,
  KonfigurasiAdminRoleUser,
  FormRole,
  KonfigurasiAdminSistem,
  KonfigurasiAdminTemplateNotifikasi,
  FinalisasiBeritaIklanAdmin,
  PreviewBeritaIklanAdmin,
  BeritaIklanAdmin,
  MasterAdminBank,
  MasterAdminBankTambah,
  MasterAdminBankUbah,
  MasterAdminPublisher,
  MasterAdminUser,
  MasterAdminEditUser,
  MasterAdminTambahUser,
  MasterAdminLagu,
  MasterAdminLaguEdit,
  MasterAdminLaguTambah,
  MasterAdminPencipta,
  MasterAdminPenciptaEdit,
  MasterAdminUserPencipta,
  MasterAdminUserPublisher,
  ManajemenWebAdmin,
  PreviewManajemenWebAdmin,
  EditDsp,
  TambahDsp,
  AddPublisher,
  EditPublisher,
  PreviewFinalisasiBerita,
  MasterAdminDsp,
} from "pages/Admin";

// =========== Composer Page page Views =============//

import {
  ProfilePencipta,
  PermintaanPembayaran,
  AjukanPembayaran,
  PenciptaReviewLagu,
  PenciptaDetailReviewLagu,
  DashboardPencipta,
  DetailLaguPencipta,
  DetailPublisher,
  KontrakListPencipta,
  KontrakListDetailPencipta,
  KontrakDokumenPendukungPencipta,
  KontrakAktivitasPencipta,
  LoanPagePencipta,
  DetailLoanPencipta,
  AddLoanPencipta,
  PaymentPencipta,
  PenciptaAddPayment,
  PenciptaDetailPayment,
  PaymentPenciptaMutation,
} from "pages/Pencipta";

// =========== Notification Page page Views =============//

import NotificationPage from "../../pages/NotificationPage";

// =========== Landing page Views =============//
import Otp from "../../pages/LandingPage/Otp/Otp";
import TentangKamiView from "../../pages/LandingPage/TentangKami/TentangKami";
import EdukasiView from "../../pages/LandingPage/Edukasi/Edukasi";
import ViewBerita from "../../pages/LandingPage/Berita";
import BeritaDetailView from "../../pages/LandingPage/Berita/DetailBerita";
import DetailLagu from "../../pages/LandingPage/DetailLagu/DetailLagu";
// =========== End Landing page Views =============//

// =========== MPIS Route Views =============//
import NewPassword from "../../pages/LandingPage/NewPassword/NewPassword";
import ChangePassword from "../../pages/changePassword";
import InvitationPassword from "../../pages/InvitationPassword/InvitationPassword";
import RedirectPage from "../../pages/RedirectPage";

import BeritaIklanPublisher from "../../pages/Publisher/BeritaIklanPublisher";
import TambahBerita from "../../pages/Publisher/BeritaIklanPublisher/TambahBerita";
import EditBerita from "../../pages/Publisher/BeritaIklanPublisher/EditBerita";
import DetailBerita from "../../pages/Publisher/BeritaIklanPublisher/DetailBerita";
import PreviewBeritaTambah from "../../pages/Publisher/BeritaIklanPublisher/PreviewBeritaTambah";
import PreviewBeritaEdit from "../../pages/Publisher/BeritaIklanPublisher/PreviewBeritaEdit";

import DashboardPublisher from "../../pages/Publisher/Dashboard";
import PrintReportPage from "../../pages/Publisher/Dashboard/PrintReportPage";
import PersonalisasiPublisher from "../../pages/Publisher/KonfigurasiPublisher/Personalisasi";
import CostType from "../../pages/Publisher/KonfigurasiPublisher/CostType";
import PembayaranPublisher from "../../pages/Publisher/KonfigurasiPublisher/Pembayaran";
import OriginalPublisherPublisher from "../../pages/Publisher/KonfigurasiPublisher/OriginalPublisher";
import AktifitasPublisher from "../../pages/Publisher/KonfigurasiPublisher/Aktifitas";
import ProfilePublisher from "../../pages/Publisher/Profile";
import KontrakPublisher from "../../pages/Publisher/Kontrak";
import DetailKontrakPublisher from "../../pages/Publisher/Kontrak/DetailKontrak";
import KontrakPublisherTambah from "../../pages/Publisher/Kontrak/TambahKontrak";
import KontrakOriginalPublisher from "../../pages/Publisher/KontrakOriginalPublisher";
import KontrakOriginalPublisherTambah from "../../pages/Publisher/KontrakOriginalPublisher/TambahKontrak";
import KontrakOriginalPublisherEdit from "../../pages/Publisher/KontrakOriginalPublisher/EditKontrak";
import DetailKontrakOriginalPublisher from "../../pages/Publisher/KontrakOriginalPublisher/DetailKontrak";
import ComposerContract from "../../pages/Publisher/ComposerContract";
import ComposerContractAdd from "../../pages/Publisher/ComposerContract/AddContract";
import ComposerContractEdit from "../../pages/Publisher/ComposerContract/EditContract";
import ComposerContractDetail from "../../pages/Publisher/ComposerContract/ContractDetail";

import MainKontrakComposerTambah from "../../pages/Publisher/KontrakComposer/MainContract/TambahKontrak";
import MainKontrakComposerEdit from "../../pages/Publisher/KontrakComposer/MainContract/EditKontrak";
import MainDetailKontrakComposer from "../../pages/Publisher/KontrakComposer/MainContract/DetailKontrak";

import MasterPublisherPencipta from "../../pages/Publisher/Master/Pencipta";
import MasterPublisherPenciptaTambah from "../../pages/Publisher/Master/Pencipta/TambahPencipta";
import MasterPublisherPenciptaEdit from "../../pages/Publisher/Master/Pencipta/EditPencipta";
import MasterPublisherPerformer from "../../pages/Publisher/Master/Performer";
import MasterPublisherPerformerTambah from "../../pages/Publisher/Master/Performer/TambahPerformer";
import MasterPublisherPerformerEdit from "../../pages/Publisher/Master/Performer/EditPerformer";
import MasterPublisherUser from "../../pages/Publisher/Master/User";
import MasterPublisherUserTambah from "../../pages/Publisher/Master/User/TambahUser";
import MasterPublisherUserEdit from "../../pages/Publisher/Master/User/EditUser";

import MasterPublisherTax from "../../pages/Publisher/Master/Tax";

import MasterPublisherLagu from "../../pages/Publisher/Master/Lagu";
import MasterPublisherLaguTambah from "../../pages/Publisher/Master/Lagu/TambahLagu";
import MasterPublisherLaguEdit from "../../pages/Publisher/Master/Lagu/EditLagu";
import MasterPublisherDsp from "../../pages/Publisher/Master/Dsp";
import DetailDSP from "../../pages/Publisher/Master/Dsp/detail";
import MasterClientAdd from "../../pages/Publisher/Master/Dsp/AddClient";
import EditClient from "../../pages/Publisher/Master/Dsp/EditClient";

import ReviewLagu from "../../pages/Publisher/ReviewLagu";
import DetailReviewLagu from "../../pages/Publisher/ReviewLagu/DetailReviewLagu";

import ManajemenWeb from "../../pages/Publisher/ManajemenWeb";
import PreviewManajemenWeb from "../../pages/Publisher/ManajemenWeb/PreviewManajemenWeb";

// =========== End of MPIS Route Views =============//

// =========== End of MPIS Route Views =============//

// =========== Routes ==================
import adminRoutes from "../adminRoutes";
import publisherRoutes from "../publisherRoutes";

import LoanPage from "../../pages/Publisher/LoanPage";
import DetailLoan from "../../pages/Publisher/LoanPage/DetailLoan";
import AddLoan from "../../pages/Publisher/LoanPage/AddLoan";
import ClaimAndUsage from "../../pages/Publisher/ClaimAndUsage";
// import AddInvoice from "../../pages/Publisher/ClaimAndUsage/AddClaimAndUsage";
import AddInvoice from "../../pages/Publisher/Invoice/AddInvoice";
import DetailClaimAndUsage from "../../pages/Publisher/ClaimAndUsage/DetailClaimAndUsage";
// import EditInvoice from "../../pages/Publisher/ClaimAndUsage/EditClaimAndUsage";
import EditInvoice from "../../pages/Publisher/Invoice/EditInvoice";
import TrendComposer from "../../pages/Publisher/Dashboard/TrendComposer";
import InvoiceConf from "../../pages/Publisher/KonfigurasiPublisher/Invoice";
import ContractReminder from "../../pages/Publisher/KonfigurasiPublisher/ContractReminder";

import AdvancedPageFinance from "../../pages/Finance/AdvancedPage";
import AddAdvancedFinance from "../../pages/Finance/AdvancedPage/AddAdvanced";
import DetailAdvancedFinance from "../../pages/Finance/AdvancedPage/DetailAdvanced";
import PrintPdfAdvancedPage from "../../pages/Finance/AdvancedPage/DetailAdvanced/PrintPdf";

import PaymentPage from "../../pages/Finance/PaymentPage";
import AddPayment from "../../pages/Finance/PaymentPage/AddPayment";
import DetailPayment from "../../pages/Finance/PaymentPage/DetailPayment";

import LoanPageFinance from "../../pages/Publisher/LoanPage";
import DetailLoanFinance from "../../pages/Publisher/LoanPage/DetailLoan";
import AddLoanFinance from "../../pages/Publisher/LoanPage/AddLoan";
import EditLoanFinance from "../../pages/Publisher/LoanPage/EditLoan";

import PrintReportReview from "../../pages/Publisher/ReviewLagu/PrintReportReview";

import PaymentMutation from "../../pages/Finance/PaymentPage/PaymentMutation";
import SongClaim from "../../pages/Publisher/SongClaim";
import DetailSongClaim from "../../pages/Publisher/SongClaim/DetailSongClaim";
import PrintReportDetailReview from "../../pages/Publisher/ReviewLagu/DetailReviewLagu/PrintReportDetailReview";

import ReportView from "../../pages/Publisher/Report";
import DetailReportView from "../../pages/Publisher/Report/DetailReport";
import CostAllocation from "../../pages/Publisher/CostAllocation";
import DefaultTresshold from "../../pages/Publisher/CostAllocation/DefaultTresshold";
import CreateTresshold from "../../pages/Publisher/CostAllocation/CreateTresshold";
import DetailCostAllocation from "../../pages/Publisher/CostAllocation/DetailCostAllocation";

import MasterPublisherAgent from "../../pages/Publisher/Master/Agent";
import MasterPublisherAgentTambah from "../../pages/Publisher/Master/Agent/TambahAgent";
import MasterPublisherAgentEdit from "../../pages/Publisher/Master/Agent/EditAgent";

import MasterCurrency from "../../pages/Publisher/Master/Currency";
import MasterCurrencyTambah from "../../pages/Publisher/Master/Currency/CreateCurrency";
import MasterCurrencyEdit from "../../pages/Publisher/Master/Currency/EditCurrency";

import CreateTax from "../../pages/Publisher/Master/Tax/CreateTax";
import EditTax from "../../pages/Publisher/Master/Tax/EditTax";
import TambahCostType from "../../pages/Publisher/KonfigurasiPublisher/CostType/TambahCostType";
import EditCostType from "../../pages/Publisher/KonfigurasiPublisher/CostType/EditCostType";

import ClientClassification from "../../pages/Publisher/Master/ClientClassification";
import AddClientClassification from "../../pages/Publisher/Master/ClientClassification/AddClientClassification";

import masterGroup from "../../pages/Publisher/Master/Group";
import AddMasterGroup from "../../pages/Publisher/Master/Group/AddMasterGroup";
import DetailMasterGroup from "../../pages/Publisher/Master/Group/DetailMasterGroup";

import ComposerCredential from "../../pages/Publisher/KonfigurasiPublisher/ComposerCredential";
import DownloadMonitoring from "../../pages/Publisher/DownloadMonitoring";
import DownloadCCID from "../../pages/Publisher/DownloadCCID";

import UploadMonitoring from "../../pages/Publisher/UploadMonitoring";
import DetailMonitor from "../../pages/Publisher/UploadMonitoring/DetailMonitor";
import ExchangesRate from "../../pages/Publisher/ExchangesRate";
import AddExRate from "../../pages/Publisher/ExchangesRate/AddExRate/AddExRate";
import ViewExRate from "../../pages/Publisher/ExchangesRate/ViewExRate/ViewExRate";

import UploadData from "../../pages/Publisher/UploadData";
import DetailUpload from "../../pages/Publisher/UploadData/DetailUpload/DetailUpload";
import AddUpload from "../../pages/Publisher/UploadData/AddUpload/AddUpload";

import DetailDalamNegri from "../../pages/Publisher/ClaimAndUsage/DetailDalamNegri";
import DetailLuarNegri from "../../pages/Publisher/ClaimAndUsage/DetailLuarNegri";

import DoubleClaim from "../../pages/Publisher/SongClaim/DoubleClaim";
import SongUnclaim from "../../pages/Publisher/SongClaim/SongUnclaim";
import UnclaimMonitoring from "../../pages/Publisher/SongClaim/UnclaimMonitoring";
import DetailUnclaimMonitoring from "../../pages/Publisher/SongClaim/UnclaimMonitoring/DetailUnclaimMonitoring";
import ManageRole from "../../pages/LandingPage/ManageRole/ManageRole";
import ManageComposer from "../../pages/LandingPage/ManageComposer/ManageComposer";

import SubReporting from "../../pages/Publisher/KonfigurasiPublisher/SubReporting";
import DetailSubReporting from "../../pages/Publisher/KonfigurasiPublisher/SubReporting/DetailSubReporting";
import SubReportingApproval from "../../pages/Publisher/KonfigurasiPublisher/SubReportingApproval";
import SubmitDSP from "../../pages/Publisher/SubmitDSP";

// switch case
let routesUsed = [];
let roleUsed = "all";

switch (roleUsed) {
  case "admin":
    // code block
    routesUsed = adminRoutes;
    break;
  case "publisher":
    // code block
    routesUsed = publisherRoutes;
    break;
  default:
    // code block
    routesUsed = [
      {
        path: "/",
        exact: true,
        component: LoginCaris,
      },
      {
        path: "/redirect/:token",
        exact: true,
        component: RedirectPage,
      },
      {
        path: "/manage-role",
        exact: true,
        isAuth: false,
        component: ManageRole,
      },
      {
        path: "/manage-composer",
        exact: true,
        isAuth: false,
        component: ManageComposer,
      },
      {
        path: "/otp",
        exact: true,
        isAuth: false,
        component: Otp,
      },
      // {
      //   path: "/home",
      //   exact: true,
      //   isAuth: false,
      //   component: Home,
      // },
      {
        path: "/login",
        exact: true,
        isAuth: false,
        component: LoginCaris,
      },
      {
        path: "/privacy-policy",
        exact: true,
        isAuth: false,
        component: PrivacyPolicy,
      },
      {
        path: "/new-password/:token",
        exact: true,
        component: NewPassword,
      },
      {
        path: "/invitation/password/:token",
        exact: true,
        isAuth: false,
        component: InvitationPassword,
      },
      {
        path: "/berita",
        exact: true,
        isAuth: false,
        component: ViewBerita,
      },
      {
        path: "/artikel/detail/:id",
        exact: true,
        isAuth: false,
        component: BeritaDetailView,
      },
      {
        path: "/detail-lagu",
        exact: true,
        isAuth: false,
        component: DetailLagu,
      },
      {
        path: "/edukasi",
        exact: true,
        isAuth: false,
        component: EdukasiView,
      },
      {
        path: "/tentang-kami",
        exact: true,
        isAuth: false,
        component: TentangKamiView,
      },
      {
        path: "/auth",
        component: AuthLayout,
        routes: [
          {
            path: "/auth/login",
            exact: true,
            component: lazy(() => import("../../views/Login")),
          },
          {
            path: "/auth/register",
            exact: true,
            component: lazy(() => import("../../views/Register")),
          },
          {
            component: () => <Redirect to="/errors/error-404" />,
          },
        ],
      },
      {
        path: "/errors",
        component: ErrorLayout,
        routes: [
          {
            path: "/errors/error-401",
            exact: true,
            component: lazy(() => import("../../views/Error401")),
          },
          {
            path: "/errors/error-404",
            exact: true,
            component: lazy(() => import("../../views/Error404")),
          },
          {
            path: "/errors/error-500",
            exact: true,
            component: lazy(() => import("../../views/Error500")),
          },
          {
            component: () => <Redirect to="/errors/error-404" />,
          },
        ],
      },
      {
        path: "/admin",
        component: DashboardAdminLayout,
        routes: [
          {
            path: "/admin/dashboard",
            exact: true,
            role: ["publisher"],
            component: () => <DashboardPublisher roles={["publisher"]} />,
          },
          {
            path: "/admin/song-claim",
            exact: true,
            component: SongClaim,
          },
          {
            path: "/admin/song-claim/:id",
            exact: true,
            component: DetailSongClaim,
          },
          {
            path: "/admin/publisher/song-unclaim",
            exact: true,
            component: SongUnclaim,
          },
          {
            path: "/admin/publisher/double-claim",
            exact: true,
            component: DoubleClaim,
          },
          {
            path: "/admin/publisher/unclaim-monitoring",
            exact: true,
            component: UnclaimMonitoring,
          },
          {
            path: "/admin/publisher/unclaim-monitoring/:id",
            exact: true,
            component: DetailUnclaimMonitoring,
          },
          {
            path: "/admin/dashboard/print-report",
            exact: true,
            component: PrintReportPage,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/composer-trend",
            exact: true,
            component: TrendComposer,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/loan",
            exact: true,
            component: LoanPage,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/loan/add",
            exact: true,
            component: AddLoan,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/advance",
            exact: true,
            component: AdvancedPageFinance,
          },

          {
            path: "/admin/advance/add",
            exact: true,
            component: AddAdvancedFinance,
          },
          {
            path: "/admin/advance/:id",
            exact: true,
            component: DetailAdvancedFinance,
          },
          {
            path: "/admin/default-tresshold",
            exact: true,
            component: DefaultTresshold,
          },
          {
            path: "/admin/cost-allocation",
            exact: true,
            component: CostAllocation,
          },
          {
            path: "/admin/create-tresshold",
            exact: true,
            component: CreateTresshold,
          },
          {
            path: "/admin/detail-cost-allocation/:id",
            exact: true,
            component: DetailCostAllocation,
          },
          {
            path: "/admin/payment",
            exact: true,
            component: PaymentPage,
          },
          {
            path: "/admin/payment/add",
            exact: true,
            component: AddPayment,
          },
          {
            path: "/admin/payment/:id",
            exact: true,
            component: DetailPayment,
          },
          {
            path: "/admin/payment-mutation",
            exact: true,
            component: PaymentMutation,
          },
          {
            path: "/admin/loan/edit/:id",
            exact: true,
            component: EditLoanFinance,
          },
          {
            path: "/admin/loan/:id",
            exact: true,
            component: DetailLoan,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/claim-and-usage",
            exact: true,
            component: ClaimAndUsage,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/upload",
            exact: true,
            component: UploadData,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/add-upload/:id",
            exact: true,
            component: AddUpload,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/detail-upload/:id",
            exact: true,
            component: DetailUpload,
            role: ["publisher", "admin"],
          },

          {
            path: "/admin/upload-monitoring",
            exact: true,
            component: UploadMonitoring,
            role: ["publisher", "admin"],
          },

          {
            path: "/admin/detail-upload-monitoring/:id",
            exact: true,
            component: DetailMonitor,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/claim-and-usage/add",
            exact: true,
            component: AddInvoice,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/claim-and-usage/:id",
            exact: true,
            component: DetailClaimAndUsage,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/claim-and-usage/edit/:id",
            exact: true,
            component: EditInvoice,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/profile",
            exact: true,
            component: ProfilePublisher,
            role: ["publisher", "admin"],
          },

          {
            path: "/admin/berita-publisher",
            exact: true,
            component: BeritaIklanPublisher,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/notifikasi",
            exact: true,
            component: NotificationPage,
          },
          {
            path: "/admin/berita-publisher/edit/preview",
            exact: true,
            component: PreviewBeritaEdit,
          },
          {
            path: "/admin/berita-publisher/edit/:id",
            exact: true,
            component: EditBerita,
          },
          {
            path: "/admin/berita-publisher/detail/:id",
            exact: true,
            component: DetailBerita,
          },
          {
            path: "/admin/berita-publisher/tambah",
            exact: true,
            component: TambahBerita,
          },
          {
            path: "/admin/berita-publisher/tambah/preview",
            exact: true,
            component: PreviewBeritaTambah,
          },

          {
            path: "/admin/master/tax",
            exact: true,
            component: MasterPublisherTax,
          },
          {
            path: "/admin/master/tax/tambah",
            exact: true,
            component: CreateTax,
          },

          {
            path: "/admin/master/tax/edit/:id",
            exact: true,
            component: EditTax,
          },
          {
            path: "/admin/invoice",
            exact: true,
            component: ClaimAndUsage,
            role: ["publisher", "admin"],
          },

          {
            path: "/admin/invoice/add",
            exact: true,
            component: AddInvoice,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/invoice/:id",
            exact: true,
            component: DetailClaimAndUsage,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/invoice/:id",
            exact: true,
            component: DetailDalamNegri,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/invoice/:id",
            exact: true,
            component: DetailLuarNegri,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/invoice/edit/:id",
            exact: true,
            component: EditInvoice,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/master/pencipta",
            exact: true,
            component: MasterPublisherPencipta,
          },
          {
            path: "/admin/master/pencipta/tambah",
            exact: true,
            component: MasterPublisherPenciptaTambah,
          },
          {
            path: "/admin/master/pencipta/edit/:id",
            exact: true,
            component: MasterPublisherPenciptaEdit,
          },
          {
            path: "/admin/master/client-classification",
            exact: true,
            component: ClientClassification,
          },
          {
            path: "/admin/master/client-classification/add",
            exact: true,
            component: AddClientClassification,
          },
          {
            path: "/admin/master/group",
            exact: true,
            component: masterGroup,
          },
          {
            path: "/admin/master/group/add",
            exact: true,
            component: AddMasterGroup,
          },
          {
            path: "/admin/master/group/detail/:id",
            exact: true,
            component: DetailMasterGroup,
          },
          {
            path: "/admin/master/lagu",
            exact: true,
            component: MasterPublisherLagu,
          },
          {
            path: "/admin/master/lagu/tambah",
            exact: true,
            component: MasterPublisherLaguTambah,
          },
          {
            path: "/admin/master/lagu/edit/:id",
            exact: true,
            component: MasterPublisherLaguEdit,
          },
          {
            path: "/admin/master/agent",
            exact: true,
            component: MasterPublisherAgent,
          },
          {
            path: "/admin/master/agent/tambah",
            exact: true,
            component: MasterPublisherAgentTambah,
          },
          {
            path: "/admin/master/agent/edit/:id",
            exact: true,
            component: MasterPublisherAgentEdit,
          },

          {
            path: "/admin/master/currency",
            exact: true,
            component: MasterCurrency,
          },
          {
            path: "/admin/master/currency/tambah",
            exact: true,
            component: MasterCurrencyTambah,
          },
          {
            path: "/admin/master/currency/edit/:id",
            exact: true,
            component: MasterCurrencyEdit,
          },
          {
            path: "/admin/master/dsp",
            exact: true,
            component: MasterPublisherDsp,
          },
          {
            path: "/admin/master/client/add",
            exact: true,
            component: MasterClientAdd,
          },
          {
            path: "/admin/master/client/edit/:id",
            exact: true,
            component: EditClient,
          },
          {
            path: "/admin/master/dsp/detail/:id",
            exact: true,
            component: DetailDSP,
          },
          {
            path: "/admin/master/performer",
            exact: true,
            component: MasterPublisherPerformer,
          },
          {
            path: "/admin/master/performer/tambah",
            exact: true,
            component: MasterPublisherPerformerTambah,
          },
          {
            path: "/admin/master/performer/ubah/:id",
            exact: true,
            component: MasterPublisherPerformerEdit,
          },
          {
            path: "/admin/master/user",
            exact: true,
            component: MasterPublisherUser,
          },
          {
            path: "/admin/master/user/tambah",
            exact: true,
            component: MasterPublisherUserTambah,
          },
          {
            path: "/admin/master/user/edit/:id",
            exact: true,
            component: MasterPublisherUserEdit,
          },
          {
            path: "/admin/master/client-classification",
            exact: true,
            component: ClientClassification,
          },
          {
            path: "/admin/konfigurasi/personalisasi",
            exact: true,
            component: PersonalisasiPublisher,
          },
          {
            path: "/admin/konfigurasi/add-cost-type",
            exact: true,
            component: TambahCostType,
          },
          {
            path: "/admin/konfigurasi/edit-cost-type/:id",
            exact: true,
            component: EditCostType,
          },
          {
            path: "/admin/konfigurasi/cost-type",
            exact: true,
            component: CostType,
          },
          {
            path: "/admin/konfigurasi/composer-credential",
            exact: true,
            component: ComposerCredential,
          },
          {
            path: "/admin/konfigurasi/pembayaran",
            exact: true,
            component: PembayaranPublisher,
          },

          {
            path: "/admin/konfigurasi/invoice",
            exact: true,
            component: InvoiceConf,
          },
          {
            path: "/admin/konfigurasi/contract-reminder",
            exact: true,
            component: ContractReminder,
          },
          {
            path: "/admin/konfigurasi/original-publisher",
            exact: true,
            component: OriginalPublisherPublisher,
          },
          {
            path: "/admin/konfigurasi/tax",
            exact: true,
            component: MasterPublisherTax,
          },
          {
            path: "/admin/konfigurasi/aktifitas",
            exact: true,
            component: AktifitasPublisher,
          },

          {
            path: "/admin/kontrak-publisher",
            exact: true,
            component: KontrakPublisher,
          },
          {
            path: "/admin/kontrak-publisher/detail/:id",
            exact: true,
            component: DetailKontrakPublisher,
          },
          {
            path: "/admin/kontrak-original-publisher",
            exact: true,
            component: KontrakOriginalPublisher,
          },
          {
            path: "/admin/kontrak-original-publisher/tambah",
            exact: true,
            component: KontrakOriginalPublisherTambah,
          },
          {
            path: "/admin/kontrak-original-publisher/edit/:id",
            exact: true,
            component: KontrakOriginalPublisherEdit,
          },
          {
            path: "/admin/kontrak-original-publisher/:id",
            exact: true,
            component: DetailKontrakOriginalPublisher,
          },
          {
            path: "/admin/kontrak/tambah",
            exact: true,
            component: KontrakPublisherTambah,
          },

          {
            path: "/admin/reports",
            exact: true,
            component: ReportView,
          },
          {
            path: "/admin/reports/:id",
            exact: true,
            component: DetailReportView,
          },

          {
            path: "/admin/kontrak-composer",
            exact: true,
            component: ComposerContract,
          },
          {
            path: "/admin/kontrak-composer/tambah",
            exact: true,
            component: ComposerContractAdd,
          },
          {
            path: "/admin/kontrak-composer/edit/:id",
            exact: true,
            component: ComposerContractEdit,
          },
          {
            path: "/admin/kontrak-composer/:id",
            exact: true,
            component: ComposerContractDetail,
          },

          {
            path: "/admin/kontrak-composer-main/tambah",
            exact: true,
            component: MainKontrakComposerTambah,
          },
          {
            path: "/admin/kontrak-composer-main/edit/:id",
            exact: true,
            component: MainKontrakComposerEdit,
          },
          {
            path: "/admin/kontrak-composer-main/:id",
            exact: true,
            component: MainDetailKontrakComposer,
          },
          {
            path: "/admin/review-lagu",
            exact: true,
            component: ReviewLagu,
          },
          {
            path: "/admin/review-lagu/print-report",
            exact: true,
            component: PrintReportReview,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/konfigurasi/sub-reporting",
            exact: true,
            component: SubReporting,
          },
          {
            path: "/admin/konfigurasi/sub-reporting/:id",
            exact: true,
            component: DetailSubReporting,
          },
          {
            path: "/admin/konfigurasi/sub-reporting-approval",
            exact: true,
            component: SubReportingApproval,
          },
          {
            path: "/admin/review-lagu/detail/print-report",
            exact: true,
            component: PrintReportDetailReview,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/review-lagu/:id",
            exact: true,
            component: DetailReviewLagu,
          },
          {
            path: "/admin/konfigurasi/exchange-rate",
            exact: true,
            component: ExchangesRate,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/konfigurasi/exchange-rate/add/:id/:currency",
            exact: true,
            component: AddExRate,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/konfigurasi/exchange-rate/view/:id/:currency",
            exact: true,
            component: ViewExRate,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/submitDSP",
            exact: true,
            component: SubmitDSP,
            role: ["publisher", "admin"],
          },
          {
            path: "/admin/change-password",
            exact: true,
            component: ChangePassword,
          },
          {
            path: "/admin/konfigurasi/sistem",
            exact: true,
            component: props => (
              <KonfigurasiAdminSistem {...props} mustLoggedIn={true} />
            ),
          },
          {
            path: "/admin/konfigurasi/template-notifikasi",
            exact: true,
            component: KonfigurasiAdminTemplateNotifikasi,
          },
          {
            path: "/admin/konfigurasi/pajak",
            exact: true,
            component: KonfigurasiAdminPajak,
          },
          {
            path: "/admin/konfigurasi/role-user",
            exact: true,
            component: KonfigurasiAdminRoleUser,
          },
          {
            path: "/admin/konfigurasi/role-user/tambah/:type",
            exact: true,
            component: FormRole,
          },
          {
            path: "/admin/konfigurasi/role-user/edit/:type/:role_id",
            exact: true,
            component: FormRole,
          },
          {
            path: "/admin/konfigurasi/aktifitas-admin",
            exact: true,
            component: KonfigurasiAdminAktifitas,
          },
          // end admin konfigurasi
          {
            path: "/admin/master/publisher",
            exact: true,
            component: MasterAdminPublisher,
          },
          {
            path: "/admin/master/publisher/add",
            exact: true,
            component: AddPublisher,
          },
          {
            path: "/admin/master/publisher/:id",
            exact: true,
            component: EditPublisher,
          },

          {
            path: "/admin/master/lagu-admin",
            exact: true,
            component: MasterAdminLagu,
          },
          {
            path: "/admin/master/lagu-admin/tambah",
            exact: true,
            component: MasterAdminLaguTambah,
          },
          {
            path: "/admin/master/lagu-admin/edit/:id",
            exact: true,
            component: MasterAdminLaguEdit,
          },

          {
            path: "/admin/master/user-admin",
            exact: true,
            component: MasterAdminUser,
          },
          {
            path: "/admin/master/user-admin/tambah",
            exact: true,
            component: MasterAdminTambahUser,
          },
          {
            path: "/admin/master/user-admin/edit/:id",
            exact: true,
            component: MasterAdminEditUser,
          },
          {
            path: "/admin/master/bank-admin",
            exact: true,
            component: MasterAdminBank,
          },
          {
            path: "/admin/master/bank-admin/tambah",
            exact: true,
            component: MasterAdminBankTambah,
          },
          {
            path: "/admin/master/bank-admin/ubah/:id",
            exact: true,
            component: MasterAdminBankUbah,
          },
          {
            path: "/admin/master/pencipta-admin/:id",
            exact: true,
            component: MasterAdminPenciptaEdit,
          },
          {
            path: "/admin/master/pencipta-admin",
            exact: true,
            component: MasterAdminPencipta,
          },
          {
            path: "/admin/master/dsp-admin",
            exact: true,
            component: MasterAdminDsp,
          },
          {
            path: "/admin/master/dsp-admin/add",
            exact: true,
            component: TambahDsp,
          },
          {
            path: "/admin/master/dsp-admin/:id",
            exact: true,
            component: EditDsp,
          },
          {
            path: "/admin/master/user-publisher",
            exact: true,
            component: MasterAdminUserPublisher,
          },
          {
            path: "/admin/master/user-pencipta",
            exact: true,
            component: MasterAdminUserPencipta,
          },
          {
            path: "/admin/berita",
            exact: true,
            component: BeritaIklanAdmin,
          },
          {
            path: "/admin/berita/tambah",
            exact: true,
            component: TambahBerita,
          },
          {
            path: "/admin/berita/tambah/preview",
            exact: true,
            component: PreviewBeritaTambah,
          },
          {
            path: "/admin/berita/edit/preview",
            exact: true,
            component: PreviewBeritaEdit,
          },
          {
            path: "/admin/berita/:id",
            exact: true,
            component: EditBerita,
          },
          {
            path: "/admin/finalisasi-berita",
            exact: true,
            component: FinalisasiBeritaIklanAdmin,
          },
          {
            path: "/admin/finalisasi-berita/view/preview",
            exact: true,
            component: PreviewFinalisasiBerita,
          },
          {
            path: "/admin/finalisasi-berita/view/:id",
            exact: true,
            component: PreviewBeritaIklanAdmin,
          },
          {
            path: "/admin/manajemen-web",
            exact: true,
            component: ManajemenWeb,
          },
          {
            path: "/admin/manajemen-web/preview",
            exact: true,
            component: PreviewManajemenWeb,
          },
          {
            path: "/admin/manajemen-web-admin",
            exact: true,
            component: ManajemenWebAdmin,
          },
          {
            path: "/admin/manajemen-web/preview-admin",
            exact: true,
            component: PreviewManajemenWebAdmin,
          },
          {
            path: "/admin/download-monitoring",
            exact: true,
            component: DownloadMonitoring,
          },
          {
            path: "/admin/download-ccid",
            exact: true,
            component: DownloadCCID,
          },
          {
            path: "/admin/parameter/pencipta",
            exact: true,
            component: MasterPublisherPencipta,
          },
          {
            path: "/admin/parameter/pencipta/tambah",
            exact: true,
            component: MasterPublisherPenciptaTambah,
          },
          {
            path: "/admin/parameter/pencipta/edit/:id",
            exact: true,
            component: MasterPublisherPenciptaEdit,
          },
          {
            path: "/admin/parameter/client-classification",
            exact: true,
            component: ClientClassification,
          },
          {
            path: "/admin/parameter/client-classification/add",
            exact: true,
            component: AddClientClassification,
          },
          {
            path: "/admin/parameter/group",
            exact: true,
            component: masterGroup,
          },
          {
            path: "/admin/parameter/group/add",
            exact: true,
            component: AddMasterGroup,
          },
          {
            path: "/admin/parameter/group/detail/:id",
            exact: true,
            component: DetailMasterGroup,
          },
          {
            path: "/admin/parameter/lagu",
            exact: true,
            component: MasterPublisherLagu,
          },
          {
            path: "/admin/parameter/lagu/tambah",
            exact: true,
            component: MasterPublisherLaguTambah,
          },
          {
            path: "/admin/parameter/lagu/edit/:id",
            exact: true,
            component: MasterPublisherLaguEdit,
          },

          {
            path: "/admin/parameter/agent",
            exact: true,
            component: MasterPublisherAgent,
          },
          {
            path: "/admin/parameter/agent/tambah",
            exact: true,
            component: MasterPublisherAgentTambah,
          },
          {
            path: "/admin/parameter/agent/edit/:id",
            exact: true,
            component: MasterPublisherAgentEdit,
          },
          {
            path: "/admin/parameter/dsp",
            exact: true,
            component: MasterPublisherDsp,
          },
          {
            path: "/admin/parameter/client/add",
            exact: true,
            component: MasterClientAdd,
          },
          {
            path: "/admin/parameter/client/edit/:id",
            exact: true,
            component: EditClient,
          },
          {
            path: "/admin/parameter/dsp/detail/:id",
            exact: true,
            component: DetailDSP,
          },
          {
            path: "/admin/parameter/performer",
            exact: true,
            component: MasterPublisherPerformer,
          },
          {
            path: "/admin/parameter/performer/tambah",
            exact: true,
            component: MasterPublisherPerformerTambah,
          },
          {
            path: "/admin/parameter/performer/ubah/:id",
            exact: true,
            component: MasterPublisherPerformerEdit,
          },
          {
            path: "/admin/parameter/user",
            exact: true,
            component: MasterPublisherUser,
          },
          {
            path: "/admin/parameter/user/tambah",
            exact: true,
            component: MasterPublisherUserTambah,
          },
          {
            path: "/admin/parameter/user/edit/:id",
            exact: true,
            component: MasterPublisherUserEdit,
          },
          // end mpis admin ==============================================
        ],
      },
      {
        path: "/finance",
        component: DashboardAdminLayout,
        routes: [
          {
            path: "/finance/advance",
            exact: true,
            component: AdvancedPageFinance,
          },
          {
            path: "/finance/advance/add",
            exact: true,
            component: AddAdvancedFinance,
          },
          {
            path: "/finance/advanced/print-as-pdf/:id",
            exact: true,
            component: PrintPdfAdvancedPage,
          },
          {
            path: "/finance/advance/:id",
            exact: true,
            component: DetailAdvancedFinance,
          },
          {
            path: "/finance/payment",
            exact: true,
            component: PaymentPage,
          },
          {
            path: "/finance/payment/add",
            exact: true,
            component: AddPayment,
          },
          {
            path: "/finance/payment/:id",
            exact: true,
            component: DetailPayment,
          },
          {
            path: "/finance/loan",
            exact: true,
            component: LoanPageFinance,
          },
          {
            path: "/finance/loan/add",
            exact: true,
            component: AddLoanFinance,
          },
          {
            path: "/finance/loan/:id",
            exact: true,
            component: DetailLoanFinance,
          },
        ],
      },
      //role pencipta
      {
        path: "/pencipta",
        component: DashboardAdminLayout,
        routes: [
          {
            path: "/pencipta/dashboard-pencipta",
            exact: true,
            component: () => <DashboardPencipta roles={["composer"]} />,
          },
          {
            path: "/pencipta/dashboard-pencipta/detail-lagu/:id",
            exact: true,
            role: ["composer"],
            component: DetailLaguPencipta,
          },
          {
            path:
              "/pencipta/dashboard-pencipta/detail-lagu/detail-publisher/:id",
            exact: true,
            component: DetailPublisher,
          },
          {
            path: "/pencipta/kontrak",
            exact: true,
            role: ["pencipta"],
            component: KontrakListPencipta,
          },
          {
            path: "/pencipta/detail-kontrak/:id",
            exact: true,
            component: KontrakListDetailPencipta,
          },
          {
            path: "/pencipta/detail-kontrak-dokumen/:id",
            exact: true,
            component: KontrakDokumenPendukungPencipta,
          },
          {
            path: "/pencipta/detail-kontrak-aktifitas/:id",
            exact: true,
            component: KontrakAktivitasPencipta,
          },
          {
            path: "/pencipta/payment",
            exact: true,
            role: ["pencipta"],
            component: PaymentPencipta,
          },
          {
            path: "/pencipta/payment-mutation",
            exact: true,
            role: ["pencipta"],
            component: PaymentPenciptaMutation,
          },
          {
            path: "/pencipta/payment/add",
            exact: true,
            component: PenciptaAddPayment,
          },
          {
            path: "/pencipta/payment/:id",
            exact: true,
            component: PenciptaDetailPayment,
          },
          {
            path: "/pencipta/loan",
            exact: true,
            component: LoanPagePencipta,
          },
          {
            path: "/pencipta/loan/add",
            exact: true,
            component: AddLoanPencipta,
          },
          {
            path: "/pencipta/loan/:id",
            exact: true,
            component: DetailLoanPencipta,
          },
          {
            path: "/pencipta/detail-kontrak/:id",
            exact: true,
            component: KontrakListDetailPencipta,
          },
          {
            path: "/pencipta/detail-kontrak-dokumen/:id",
            exact: true,
            component: KontrakDokumenPendukungPencipta,
          },
          {
            path: "/pencipta/detail-kontrak-aktifitas/:id",
            exact: true,
            component: KontrakAktivitasPencipta,
          },
          {
            path: "/pencipta/permintaan-pembayaran",
            exact: true,
            component: PermintaanPembayaran,
          },
          {
            path: "/pencipta/permintaan-pembayaran/ajukan-pembayaran",
            exact: true,
            component: AjukanPembayaran,
          },
          {
            path: "/pencipta/profile-pencipta",
            exact: true,
            component: ProfilePencipta,
            role: ["publisher", "admin", "composer"],
          },
          {
            path: "/pencipta/review-lagu",
            exact: true,
            component: PenciptaReviewLagu,
          },
          {
            path: "/pencipta/review-lagu/print-report",
            exact: true,
            component: PrintReportReview,
            role: ["publisher", "admin"],
          },

          {
            path: "/pencipta/review-lagu/:id",
            exact: true,
            component: PenciptaDetailReviewLagu,
          },
        ],
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ];
}

const itemRoutes = routesUsed;

export default itemRoutes;
