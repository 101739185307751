import { Button, ButtonGroup, Container, Divider } from "@material-ui/core";
import { Grid, Tooltip } from "@mui/material";
import axios from "axios";
import {
  ModalError,
  ModalSuccess,
  ModalWarning,
  Page,
  PrimaryButton,
  SkeletonComponent,
} from "components";
import { HeaderTitle } from "layouts";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { globalStyles } from "styles";
import { getErrors } from "utils";
import { hardBaseUrl } from "../../../services/urlConstant";
import ContractDetailForm from "./Components/ContractDetailForm";
import SupportingDocuments from "./Components/SupportingDocuments";

const ContractDetail = () => {
  const classes = globalStyles();
  const history = useHistory();
  const { id } = useParams();
  const { state } = useLocation();
  const { is_editable, is_active_flag } = state?.customer;
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [selectedMenu, setSelectedMenu] = useState(1);
  const [loadingPage, setLoadingPage] = useState(false);

  const handleDeactivateContract = () =>
    ModalWarning(
      "Are you sure you want to deactivate this contract?",
      "Deactivate Contract"
    ).then(res => res?.isConfirmed && deactivateContract());

  const deactivateContract = async () => {
    try {
      setLoadingPage(true);
      await axios.put(
        `${hardBaseUrl}/publisher/contract/set-inactive/${id}`,
        {},
        { headers }
      );
      ModalSuccess("Status Has Been Updated").then(() => history.goBack());
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  return (
    <Page className={classes.root} title="Contract Detail">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title="Contract Detail"
            breadcrumbData={breadcrumbData}
            backButton
          />
          <Grid container justifyContent="space-between" mt="20px">
            <Grid item>
              <ButtonGroup>
                {menuContract?.map(({ title, id }) => {
                  const selected = id === selectedMenu;
                  return (
                    <Button
                      className={
                        selected
                          ? classes.groupButtonSelected
                          : classes?.groupButton
                      }
                      key={id}
                      onClick={() => {
                        setSelectedMenu(id);
                      }}
                    >
                      {title}
                    </Button>
                  );
                })}
              </ButtonGroup>
            </Grid>
            <Grid item>
              <Grid container columnSpacing="16px">
                <Grid item>
                  {is_active_flag && (
                    <PrimaryButton
                      label="Deactivate"
                      onClick={handleDeactivateContract}
                      width={160}
                    />
                  )}
                </Grid>
                <Tooltip title={!is_editable && "Contract is not editable"}>
                  <Grid item>
                    <PrimaryButton
                      label="Edit Contract"
                      onClick={() =>
                        history.push(`/admin/kontrak-composer/edit/${id}`)
                      }
                      disabled={!is_editable}
                      width={160}
                    />
                  </Grid>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          {selectedMenu === 1 ? (
            <ContractDetailForm preload={state?.customer} />
          ) : (
            <SupportingDocuments
              idKontrak={state.customer.contract_id}
              contractType={state.customer.category}
            />
          )}
        </Container>
      )}
    </Page>
  );
};
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Contract",
    link: "/admin/kontrak-composer",
  },
  {
    label: "Contract Detail",
    active: true,
  },
];
const menuContract = [
  {
    id: 1,
    title: "Detail",
  },
  {
    id: 2,
    title: "Supporting Document",
  },
];

export default ContractDetail;
