import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  Box,
  Grid,
  Pagination,
  Skeleton,
  styled,
  Typography,
} from "@mui/material";
import { SelectInput } from "components";
import { getCookie } from "utils";

const CustomTable = ({
  columnTable,
  data,
  rowClick,
  emptyPlaceHolder,
  page,
  pageSize,
  pageCount,
  handleChangePage,
  handleChangePageSize,
  isLoading,
  color,
}) => {
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);
  return isLoading ? (
    <Skeleton variant="rounded" height={300} animation="wave"/>
  ) : (
    <Box border="1px solid #9AA2B1" borderRadius="6px" marginTop="20px">
      <CustomTableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {columnTable?.map((item, index) => (
                <TableCell key={index} width={item?.width}>
                  <Typography fontSize="14px" color="#687083" fontWeight="bold">
                    {item?.title}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length > 0 ? (
              data?.map((value, index) => (
                <TableRow
                  hover
                  key={index}
                  style={
                    rowClick && {
                      cursor: "pointer",
                    }
                  }
                  onClick={event => {
                    if (rowClick) {
                      event.preventDefault();
                      rowClick(value);
                    }
                  }}
                >
                  {columnTable?.map((item, idx) => (
                    <TableCell key={idx} width={item?.width}>
                      <Typography fontSize="14px" color="#111827">
                        {item?.renderText
                          ? item?.renderText(value)
                          : value?.[item?.name] || "-"}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={12}>
                  <center>
                    <Typography
                      fontWeight={400}
                      color="#687083"
                      fontSize="14px"
                      fontStyle="italic"
                    >
                      {emptyPlaceHolder || "No Data"}
                    </Typography>
                  </center>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CustomTableContainer>
      {pageCount > 0 && (
        <Box p="16px 24px">
          <Grid container justifyContent="space-between">
            <Grid item>
              <Grid container columnSpacing={1} alignItems="center">
                <Grid item>
                  <Typography color="#687083" font-size="14px">
                    Item per page:
                  </Typography>
                </Grid>
                <Grid item>
                  <SelectInput
                    onChange={handleChangePageSize}
                    options={pageSizeList}
                    value={pageSize}
                    optionKey="name"
                    optionLabel="name"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <CustomPagination
                count={pageCount}
                shape="rounded"
                customColor={color || userLogin?.publisher?.theme_color}
                page={page}
                onChange={handleChangePage}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};
const CustomTableContainer = styled(TableContainer)({
  maxHeight: 250,
});
const CustomPagination = styled(Pagination)(({ customColor }) => ({
  "& .MuiButtonBase-root.Mui-selected": {
    backgroundColor: `${customColor || "#111827"}`,
    color: "white",
    "&:hover": {
      backgroundColor: `${customColor || "#111827"}`,
    },
  },
}));
const pageSizeList = [{ name: 10 }, { name: 20 }, { name: 50 }];

export default CustomTable;
